import styles from './Section1.module.scss';
import { data } from './Data';

export default function Section1() {
  return (
    <section className={styles.container} id="Seccion1">
      <div className={styles.content}>
        <div className={styles.cardsContent}>
          {data.card1.map((item, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.imgContent}>
                <img src={item.image} alt={item.title} loading="lazy" />
              </div>
              <div className={styles.text}>
                <h3>{item.title}</h3>
                <p>{item.paragraph}</p>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 16.5V18.75C3 19.3467 3.23705 19.919 3.65901 20.341C4.08097 20.7629 4.65326 21 5.25 21H18.75C19.3467 21 19.919 20.7629 20.341 20.341C20.7629 19.919 21 19.3467 21 18.75V16.5M16.5 12L12 16.5M12 16.5L7.5 12M12 16.5V3"
                      stroke="#6F4EF6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.cardsContent}>
          {data.card2.map((item, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.imgContent} id={styles.imgContent}>
                <img src={item.image} alt={item.title} loading="lazy" />
              </div>
              <div className={styles.text}>
                <h3>{item.title}</h3>
                <p>{item.paragraph}</p>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 16.5V18.75C3 19.3467 3.23705 19.919 3.65901 20.341C4.08097 20.7629 4.65326 21 5.25 21H18.75C19.3467 21 19.919 20.7629 20.341 20.341C20.7629 19.919 21 19.3467 21 18.75V16.5M16.5 12L12 16.5M12 16.5L7.5 12M12 16.5V3"
                      stroke="#6F4EF6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
