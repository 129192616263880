import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Section1.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { cards } from '../../Blog.posts.feature/posts.markdown/dataPosts';
import degrade from '../../Blog.posts.feature/posts.markdown/assets/overlay.png';
import scrollToTop from "../../../hooks/scrollToTop"

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

export default function Section1() {
  return (
    <section className={styles.container} id="seccion1">
      <div className={styles.content}>
        <div className={styles.title}>
          <h1>Los mejores contenidos los encuentras en Proyment.</h1>
          <h2>
            Transformación Digital, Proyectos, Finanzas, Recursos Humanos y más.
          </h2>
        </div>
        <div className={styles.swiperContent}>
          <Swiper
            modules={[Autoplay]}
            loop={true}
            autoplay={{
              delay: 3000,
              /* pauseOnMouseEnter: false,
              disableOnInteraction: false, */
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              375: {
                slidesPerView: 1.15,
                spaceBetween: 0,
              },
              425: {
                slidesPerView: 1.15,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
              1440: {
                slidesPerView: 4,
                spaceBetween: 0,
              },
            }}
            className={styles.slideContent}
          >
            {cards.map((item, index) => (
              <SwiperSlide key={index}>
                <Link to={`/blog/${item.metadaBlog.tags}/${item.title.replace(/ /g, '-')}`} onClick={scrollToTop}>
                  <div className={styles.card}>
                    <img
                      src={item.img}
                      alt={item.title}
                      loading="lazy"
                      className={styles.img}
                    />
                    <img src={degrade} alt="" className={styles.degrade} />
                    <div className={styles.text}>
                      <h2>{item.title}</h2>
                      <p>{item.subtitle}</p>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
