import { Link } from 'react-router-dom';
import { tags } from '../../posts.markdown/dataPosts';
import styles from './Recomendations.module.scss';
import logo from './logo_white 2.png';
import scrollToTop from '../../../../hooks/scrollToTop';

export default function RecomendationsPosts(content) {
  //const contents = tags['finanzas'];
  const postid = content.postid;
  const contents = tags[content.tags];
  const contentsFiltered =
    contents.length > 1
      ? contents.filter((content) => content.id !== postid)
      : tags['finanzas'];
  return (
    <div className={styles.container}>
      <h3>
        Te recomendamos<span></span>
      </h3>
      <div className={styles.cardsContainer}>
        {contentsFiltered.map((content) => {
          return (
            <Link
              onClick={() => scrollToTop()}
              key={content.id}
              style={{ textDecoration: 'none' }}
              to={`/blog/${content.metadaBlog.tags}/${content.title.replace(/ /g, '-')}`}
            >
              <div className={styles.divcard}>
                <div className={styles.contentTitle}>
                  <h4>{content.title}</h4>
                  <p>{content.subtitle}</p>
                </div>
                <div className={styles.imgContainer}>
                  <div className={styles.degrade}></div>
                  <img
                    src={logo}
                    alt="ProymentLogo"
                    className={styles.logo}
                    loading="lazy"
                  />
                  <img
                    src={content.img}
                    alt={content.title}
                    className={styles.imgbackground}
                    loading="lazy"
                  />
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
