import icon0 from './assets/icon (0).svg';
import icon1 from './assets/icon (1).svg';
import icon2 from './assets/icon (2).svg';
import icon3 from './assets/icon (3).svg';
import icon4 from './assets/icon (4).svg';
import icon5 from './assets/icon (5).svg';
import icon6 from './assets/icon (6).svg';

const data = [
  { icon: icon0, text: 'El mismo método que ha llevado a empresas pequeñas y medianas a alcanzar 50,000 dólares mensuales en ventas y a empresas más consolidadas más de 1 Millón de dólares mensuales.' },
  { icon: icon1, text: 'Cómo esta metodología probada te evita cualquier dolor de cabeza en el pago de las nóminas, costos fijos y proyecciones de venta no alcanzadas.' },
  { icon: icon2, text: '5 razones por las que este sistema permite escalar el negocio al nivel que tú quieras, sin límites y sin estar metido 14 horas/día en la operación.' },
  { icon: icon3, text: 'Por qué no es necesario ser experto en marketing y ventas, y aún así vender de forma recurrente, predecible y escalable sin tanto esfuerzo.' },
  { icon: icon4, text: 'La estrategia más efectiva para que empresas quieran pagar tus servicios sin importar el precio.' },
  { icon: icon5, text: 'El secreto para lograr citas y llamadas con clientes potenciales 100% calificados y que ya estén pre-vendidos sin esfuerzos de tu parte.' },
  { icon: icon6, text: 'Cómo comenzar a disfrutar en 15 días de una fuente inagotable de clientes, copiando y pegando este sistema en tu negocio.' },
];


export default data;