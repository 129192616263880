import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PlanesDetalles.module.scss';
import scrollToTop from '../../../hooks/scrollToTop';
import Planes from './info';

export default function PlanesDetalles() {
  const pathname = useLocation().pathname;
  const navigateTo = useNavigate();
  const navigate = (param) => {
    navigateTo(param);
    scrollToTop();
  };

  let Plan = {};
  let style = {};
  if (pathname.split('/')[2] === 'plan-standard') {
    Plan = Planes[0];
    style.h2 = { maxWidth: '430px' };
  } else if (pathname.split('/')[2] === 'plan-avanzado') {
    Plan = Planes[1];
    style.h2 = { maxWidth: '330px' };
  } else if (pathname.split('/')[2] === 'plan-prime') {
    Plan = Planes[2];
    style.h2 = { maxWidth: '500px' };
    style.img = { height: '100%' };
  }

  return (
    <section id="NosEnfocamos" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h1>{Plan.title}</h1>
          <h2 style={style.h2}>{Plan.subtitle}</h2>
          <ol>
            {Plan.points.map((point, index) => (
              <li key={index}>
                <h3>
                  <span>{index + 1}. </span>
                  {point.title}
                </h3>
                <p>{point.text}</p>
              </li>
            ))}
          </ol>
          <button
            className={styles.agendarReunion}
            onClick={() => navigate('/agendar-reunion')}
          >
            ¡Quiero iniciar!
          </button>
        </div>
        <div className={styles.imgContainer}>
          <img src={Plan.img} alt={`${Plan.title}`} style={style.img} loading='lazy'></img>
        </div>
      </div>
    </section>
  );
}
