import cardsImg from './assets/Rectangle 4979.png';
import cardsImg1 from './assets/Rectangle 4979 (1).png';
import cardsImg2 from './assets/Rectangle 4979 (2).png';

const cards = [
  {
    title: 'PLAN STANDARD',
    img: cardsImg,
    points: ['Branding.', 'Redes sociales.', 'Consultoría estratégica.'],
  },
  {
    title: 'PLAN AVANZADO',
    img: cardsImg1,
    points: [
      'Imagen Empresarial.',
      'Redes sociales.',
      'Consultoría estratégica.',
      'Crecimiento de prospectos y clientes.',
    ],
  },
  {
    title: 'PLAN PRIME',
    img: cardsImg2,
    points: [
      'Imagen Empresarial.',
      'Marketing.',
      'Diseño web.',
      'Desarrollo web.',
      'Consultorías de escalamiento.',
      'Crecimiento de clientes y escala de valor.',
    ],
  },
];
const Planes = [
  {
    title: 'Potenciamos,<span> posicionar y esquematizar</span> tu empresa.',
    cards: cards,
  },
  {
    title:
      'Incrementamos tus ingresos a través de la<span> búsqueda de clientes de alto valor.</span>',
    cards: cards,
  },
  {
    title:
      'Aumentamos la rentabilidad a través del incremento<span> de la satisfacción del cliente.</span>',
    cards: cards,
  },
];

export default Planes;
