import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import NavBarV2 from './Components/NavBarV2/NavBarV2';
import FloatButtons from './Components/FloatButtons/FloatButtons';
import BannerNotification from './Components/BannerNotification/BannerNotification';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <FloatButtons/> 
      <BannerNotification />
      <NavBarV2 />
      <App />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
