import planStandard from './assets/PlanStandard.png';
import planAvanzado from './assets/PlanAvanzado.png';
import planPrime from './assets/PlanPrime.png';

const Planes = [
  {
    title: 'Plan Standard',
    subtitle:
      'Es un plan que busca potenciar, posicionar y esquematizar tu empresa.',
    points: [
      {
        title: 'Branding',
        text: 'Construimos la identidad visual de tu marca para despertar sensaciones y crear conexiones.',
      },
      {
        title: 'Redes Sociales',
        text: 'Creamos e implementamos un plan de contenidos de redes sociales para tu empresa',
      },
      {
        title: 'Consultoría estratégica',
        text: 'Asesoramos sobre diversos aspectos de la empresa para aumentar su éxito y garantizar la efectividad de sus estrategias de marketing.',
      },
    ],
    img: planStandard,
  },
  {
    title: 'Plan Avanzado',
    subtitle:
      'Es un plan que busca incrementar tus ingresos a través de la búsqueda de clientes de alto valor.',
    points: [
      {
        title: 'Imagen empresarial',
        text: 'Trabajamos en la imagen corporativa para mejorar la percepción del público sobre tu empresa.',
      },
      {
        title: 'Redes Sociales',
        text: 'Creamos e implementamos un plan de contenidos de redes sociales para tu empresa',
      },
      {
        title: 'Consultoría empresarial',
        text: 'Te asesoramos para mejorar el éxito de tu empresa.',
      },
      {
        title: 'Crecimiento de prospectos y clientes',
        text: 'Incrementamos la cantidad de clientes potenciales de alta calidad.',
      },
    ],
    img: planAvanzado,
  },
  {
    title: 'Plan Prime',
    subtitle:
      'Es un plan que busca el incremento de la rentabilidad a través del potenciamiento de tu imagen corporativa, generación de clientes de alto valor y el incremento de la satisfacción del cliente.',
    points: [
      {
        title: 'Imagen empresarial',
        text: 'Trabajamos en la imagen corporativa para mejorar la percepción del público sobre tu empresa.',
      },
      {
        title: 'Marketing',
        text: 'Brindamos servicios de Marketing Digital Estratégico, enfocados en aportar soluciones y valor a todos nuestros clientes.',
      },
      {
        title: 'Diseño web',
        text: 'Con nuestros diseñadores UI/UX comenzaremos a desarrollar una propuesta web escalable.',
      },
      {
        title: 'Desarrollo web',
        text: 'Desarrollaremos la web con tecnología utilizada en las grandes empresas como react js, node js, entre otros.',
      },
      {
        title: 'Consultorías de escalamiento',
        text: 'Te asesoramos para mejorar el crecimiento de tu empresa.',
      },
      {
        title: 'Crecimiento de clientes y escala de valor',
        text: 'Incrementamos la cantidad de clientes potenciales de alta calidad.',
      },
    ],
    img: planPrime,
  },
];

export default Planes;
