import LandingPage from "./Landing/Landing";
import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";


export default function Page(){
    return(
        <>
        <LandingPage/>
        <Section1/>
        <Section2/>
        <Section3/>
        </>
    )
}