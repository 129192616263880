import React from 'react';
import style from './LandingIndustrias.module.scss';

import Heroimage from './assets/bg.png';
import HeroMobile from './assets/img.png';

function LandingIndustrias() {
  return (
    <section className={style.container}>
      <div className={style.contentText}>
        <div className={style.contentTitle}>
          <h1>
            <span id='contentLandingEstatico'>Nuestros casos de éxito </span> y las industrias que
            contribuimos
          </h1>
        </div>
        <p className={style.paragraph}>
          Estamos preparados para contribuir con el objetivo de empresas que
          buscan trascender.
        </p>
      </div>

      <div className={style.contentImg}>
        <img src={Heroimage} alt="Heroimage.png" className={style.heroImg} />
        <img src={HeroMobile} alt="Heroimage" className={style.heroImgMobile} />
      </div>
    </section>
  );
}
export default LandingIndustrias;
