import LandingIndustrias from "./LandingIndustrias/LandingIndustrias";
import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";

export default function Page() {
    return (
      <>
      <LandingIndustrias/>
      <Section1/>
      <Section2/>
      </>
    );
  }