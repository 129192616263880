import React from 'react';
import style from './LandingRecursos.module.scss';

import Heroimage from './assets/bg1.png';
import HeroMobile from './assets/img.png';
import scrollToNextSection  from '../../../hooks/scrollToNextSection';

function LandingRecursos() {

  return (
    <section className={style.container}>
      <div className={style.contentText}>
        <div className={style.contentTitle}>
          <svg
            id="contentLandingEstatico"
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="2"
            viewBox="0 0 45 2"
            fill="none"
          >
            <path d="M0 1H45" stroke="#39E5D5" strokeWidth="2" />
          </svg>
          <h1>
            <span>Consulta los mejores </span> recursos de tu interés
          </h1>
        </div>
        <button className={style.btn} onClick={()=>scrollToNextSection("Seccion1", 549)}>Ver más</button>
      </div>

      <div className={style.contentImg}>
        <img src={Heroimage} alt="Heroimage.png" className={style.heroImg} />
        <img src={HeroMobile} alt="Heroimage" className={style.heroImgMobile} />
      </div>
    </section>
  );
}
export default LandingRecursos;
