import scrollToTop from '../../../hooks/scrollToTop';
import styles from './OPTProyment.module.scss';
import background from './assets/06sm.png';
import { useNavigate } from 'react-router-dom';

export default function OPT() {
  const navigateTo = useNavigate();
  const navigate = (param) => {
    navigateTo(param);
    scrollToTop();
  };

  return (
    <section id="Optimizamos Empresas" className={styles.container}>
      <div className={styles.contentContainer}>
        <span className={styles.line}></span>
        <h1>
          Optimizamos empresas con nuestros{' '}
          <span>servicios profesionales a medida</span>
        </h1>
        <button
          className={styles.agendarReunion}
          onClick={() => navigate('/agendar-reunion')}
        >
          Agendar reunión{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M12.9565 7.22656L18.6695 12.9396L12.9565 18.6526M5.35547 12.9396H18.6525"
              stroke="white"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className={styles.img}>
        <img src={background} alt="Oficinas" loading='lazy'></img>
      </div>
    </section>
  );
}
