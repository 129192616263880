import styles from './PlanesLanding.module.scss';
import Planes from './info';
import iconCheck from './assets/Component 32.svg';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import scrollToTop from '../../../hooks/scrollToTop';

export default function PlanesLanding() {
  const pathname = useLocation().pathname;
  const renderCard = (content) => {
    const id = content.title.split(' ').join('-').toLowerCase();
    return (
      <Link
      onClick={()=> scrollToTop()}
        key={content.title}
        to={`/productos/${id}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <div className={styles.card} id={id}>
          <h1 id={`inh1`}>{content.title}</h1>
          <div className={styles.contentContainer}>
            <img
              src={content.img}
              className={styles.imgFirst}
              alt={content.title}
            ></img>
            <ul>
              {content.points.map((point) => (
                <li key={point}>
                  <img src={iconCheck} alt="CheckIcon"></img>
                  <p>{point}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Link>
    );
  };

  let title = 'Elige el producto que más <span>potencie tu empresa</span>';
  if (pathname.split('/')[2] === 'plan-standard') {
    title = Planes[0].title;
  } else if (pathname.split('/')[2] === 'plan-avanzado') {
    title = Planes[1].title;
  } else if (pathname.split('/')[2] === 'plan-prime') {
    title = Planes[2].title;
  }
  useEffect(() => {
    if (pathname.split('/')[2]) {
      const element = document.getElementById(pathname.split('/')[2]);
      const inh1 = element.querySelector('#inh1');
      element.style.opacity = 1;
      element.style.border = '1px solid transparent';
      element.style.background =
        'linear-gradient(130deg, #000538 2.89%, #100267 100%)padding-box, linear-gradient(91deg, #8156ee 9.33%, #487ded 43.76%, #26afef 85.96%)border-box';
      //h1.style
      inh1.style.borderBottom = '1px solid transparent';
      inh1.style.background =
        'linear-gradient(91deg,#8156ee 9.33%,#487ded 43.76%,#26afef 85.96%)';
    }
  }, [pathname]);

  return (
    <section id="PlanesLanding" className={styles.section}>
      <div className={styles.container}>
        <h1
          id="contentLandingEstatico"
          className={styles.title}
          style={
            pathname === '/productos'
              ? {
                  padding: '0px',
                  textAlign: 'center',
                  fontSize: '32px',
                  fontWeight: '500',
                  lineHeight: '42px',
                  maxWidth: '500px',
                }
              : {}
          }
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className={styles.cardsContainer}>
          {Planes[0].cards.map((card) => renderCard(card))}
        </div>
      </div>
    </section>
  );
}
