import scrollToTop from '../../../hooks/scrollToTop';
import styles from './HagamosResultados.module.scss';
import mujer from './assets/mujer.png';
import { useNavigate } from 'react-router-dom';

export default function HagamosResultados() {
  const navigateTo = useNavigate();
  const navigate = (param) => {
    navigateTo(param);
    scrollToTop();
  };

  return (
    <section id="HagamosResultados" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h1>Hágamos tus resultados tan escalables como quisieras</h1>
          <h2>
            Hemos implementado exitosamente este sistema con más de 100 empresas
            en 7 países distintos donde alcanzamos resultados mensuales con el
            sistema simple, delegable y escalable.
          </h2>
          <button
            className={styles.agendarReunion}
            onClick={() => navigate('/agendar-reunion')}
          >
            Agendar reunión{' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M12.9565 7.22656L18.6695 12.9396L12.9565 18.6526M5.35547 12.9396H18.6525"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className={styles.imgContainer}>
          <img src={mujer} alt="Mujer" loading='lazy'></img>
        </div>
      </div>
    </section>
  );
}
