import { infoADNCards } from './info';
import styles from './ADN.module.scss';
/* import "react-responsive-carousel/lib/styles/carousel.min.css";*/
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/pagination';
import './Carrousel.scss';

export default function ADN() {
  return (
    <>
      <div className={styles.background}>
        <div className={styles.container}>
          <div className={styles.container1}>
            <h1 className={styles.h1}>ADN Proyment</h1>
            <p className={styles.text}>
              Alcanzamos de forma correcta la transformación empresarial en base
              de la innovación y el potenciamiento a escala.
            </p>
          </div>
          <div className={styles.cards}>
            {infoADNCards.map((info) => (
              <div key={info.id} className={styles.card}>
                <div className={styles.text}>
                  <img src={info.icons} alt="Img" />
                  <h1>{info.title}</h1>
                  <p>{info.description}</p>
                </div>
                <img
                  className={styles.bigIMG}
                  src={info.img}
                  alt={`${info.title} IMG`}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
          <div className={styles.carrousel}>
            {/* <div className="swiperContainer"> */}
            <Swiper
              style={{ margin: 0 }}
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 4000,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
              }}
              //si no gusta slides = 1
              /*  loop={true} */
              /* slidesPerView={1.25} */
              //spaceBetween={30}
              pagination={{ el: '.ADN-pagination', clickable: true }}
              className={styles.data}
              breakpoints={{
                320: {
                  slidesPerView: 1.25,
                  spaceBetween: 30,
                },
                380: {
                  slidesPerView: 1.2,
                  spaceBetween: 10,
                },
                420: {
                  slidesPerView: 1.3,
                  spaceBetween: 10,
                },
                460: {
                  slidesPerView: 1.4,
                  spaceBetween: 10,
                },
                560: {
                  slidesPerView: 1.65,
                  spaceBetween: 10,
                },
                660: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                760: {
                  slidesPerView: 2.5,
                  spaceBetween: 5,
                },
                800: {
                  slidesPerView: 2.15,
                  spaceBetween: 20,
                },
                920: {
                  slidesPerView: 2.55,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 2.65,
                  spaceBetween: 30,
                },
                1100: {
                  slidesPerView: 2.9,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
              }}
            >
              {infoADNCards.map((info) => (
                <SwiperSlide key={info.id}>
                  <div key={info.id} className={styles.card}>
                    <div className={styles.text}>
                      <img src={info.icons} alt="Img" />
                      <h1>{info.title}</h1>
                      <p>{info.description}</p>
                    </div>
                    <img
                      className={styles.bigIMG}
                      src={info.img}
                      alt="Img"
                      loading="lazy"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            {/* </div> */}
            <div className="ADN-pagination" />
          </div>
        </div>
      </div>
    </>
  );
}
