import React from 'react';
import styles from './NavBarV2.module.scss';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoWhite from './assets/logo-white.svg';
import useSuperponen from '../../hooks/useEffectSuperponen';
import scrollToTop from '../../hooks/scrollToTop';

export default function NavBarV2() {
  const [burgerSelect, setBurgerSelect] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const superponen = useSuperponen('NavBarContainer');
  const pathname = useLocation().pathname.split('/');

  const navigate = useNavigate();
  const scrollNavigate = (route) => {
    navigate(route);
    scrollToTop();
  };

  const handleNavigates = (param) => {
    navigate(param);
    handleDesactive();
    scrollToTop();
  };
  //Handle Desactive para cerrar menu tocando afuera
  const handleDesactive = () => {
    setBurgerSelect(null);
    setActiveAccordion(null);
    scrollToTop();
  };
  const handleDesactiveDiv = () => {
    setBurgerSelect(null);
    setActiveAccordion(null);
  };
  const handleAccordionClick = (index) => {
    if (activeAccordion === index) {
      setActiveAccordion(null); // Si se hace clic en el acordeón abierto, se cierra
    } else {
      setActiveAccordion(index); // Si se hace clic en un acordeón diferente, se actualiza el índice activo
    }
  };
  //Estado para Seleccionar el menu responsive
  const handleBurger = (burgerSelected) => {
    setBurgerSelect(!burgerSelected);
    setActiveAccordion(null);
  };

  return (
    <>
      <section id="NavBarContainer" className={styles.navBarContainer}>
        {/* NavBarDesktop */}
        <nav
          id="navBarDesktop"
          className={styles.navBarDesktop}
          style={
            pathname[1] !== 'agendar-reunion'
              ? superponen
                ? { background: '#000538' }
                : {}
              : { borderBottom: '1px solid #201F54', background: '#070e27' }
          }
          /* 
          NavBarWhiteLogic 
          className={`${styles.navBarDesktop} ${
             superponen && pathname[1] !== 'agendar-reunion'
              ? styles.navBarWhiteDesktop
              : {} 
          }`}
          style={
            superponen && pathname[1] !== 'agendar-reunion'
              ? { borderBottom: '2px solid #DCDDEB', background: '#FFF' }
              : {}
          } */
        >
          <img
            className={styles.logo}
            /*
            NavBarLogic
             src={!superponen || pathname[1] === 'agendar-reunion' ? logoWhite : logoBlue} 
            */
            src={logoWhite}
            alt="Proyment-Icon"
            onClick={() => scrollNavigate('/')}
          ></img>
          {/* pathname, valida que sea cualquiera menos productos */}
          {pathname[1] !== 'agendar-reunion' ? (
            pathname[1] !== 'productos' ? (
              <>
                {/* NavBar Para todo el resto de componentes */}
                <ul className={styles.menuHorizontal}>
                  <li>
                    <p>
                      Servicios
                      <span
                        style={
                          pathname[1] === 'servicios'
                            ? { background: 'white', width: '100%' }
                            : {}
                          /* 
                            NavBar White Logic
                            ? !superponen
                              ? { background: 'white', width: '100%' }
                              : { background: '#000538', width: '100%' }
                            : {} */
                        }
                      ></span>
                    </p>
                    <ul className={styles.menuVertical}>
                      <Link
                        onClick={() => scrollToTop()}
                        to="/servicios/servicios-integrales"
                        style={{
                          textDecoration: 'none',

                          color: 'inherit',
                        }}
                      >
                        <li>Servicios integrales</li>
                      </Link>

                      <Link
                        onClick={() => scrollToTop()}
                        to="/servicios/marketing-digital"
                        style={{
                          textDecoration: 'none',

                          color: 'inherit',
                        }}
                      >
                        <li>Marketing digital</li>
                      </Link>

                      <Link
                        onClick={() => scrollToTop()}
                        to="/servicios/analisis-datos"
                        style={{
                          textDecoration: 'none',

                          color: 'inherit',
                        }}
                      >
                        <li>Analisis de datos</li>
                      </Link>

                      <Link
                        onClick={() => scrollToTop()}
                        to="/servicios/diseño-web"
                        style={{
                          textDecoration: 'none',

                          color: 'inherit',
                        }}
                      >
                        <li>Diseño web UX/UI</li>
                      </Link>
                    </ul>
                  </li>

                  <li>
                    <p>
                      Productos{' '}
                      <span
                        style={
                          pathname[1] === 'productos'
                            ? { background: 'white', width: '100%' }
                            : {}
                        }
                      ></span>
                    </p>

                    <ul className={styles.menuVertical}>
                      <Link
                        onClick={() => scrollToTop()}
                        to="/productos/plan-standard"
                        style={{
                          textDecoration: 'none',

                          color: 'inherit',
                        }}
                      >
                        <li>Plan Standard</li>
                      </Link>
                      <Link
                        onClick={() => scrollToTop()}
                        to="/productos/plan-avanzado"
                        style={{
                          textDecoration: 'none',

                          color: 'inherit',
                        }}
                      >
                        <li>Plan Avanzado</li>
                      </Link>
                      <Link
                        onClick={() => scrollToTop()}
                        to="/productos/plan-prime"
                        style={{
                          textDecoration: 'none',

                          color: 'inherit',
                        }}
                      >
                        <li>Plan Prime</li>
                      </Link>
                    </ul>
                  </li>

                  <li>
                    <Link
                      onClick={() => scrollToTop()}
                      to="/recursos"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <p>
                        Recursos{' '}
                        <span
                          style={
                            pathname[1] === 'recursos'
                              ? { background: 'white', width: '100%' }
                              : {}
                          }
                        ></span>
                      </p>
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={() => scrollToTop()}
                      to="/industrias"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <p>
                        Industrias{' '}
                        <span
                          style={
                            pathname[1] === 'industrias'
                              ? { background: 'white', width: '100%' }
                              : {}
                          }
                        ></span>
                      </p>
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={() => scrollToTop()}
                      to="/sobre-nosotros"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <p>
                        Sobre Proyment{' '}
                        <span
                          style={
                            pathname[1] === 'sobre-nosotros'
                              ? { background: 'white', width: '100%' }
                              : {}
                          }
                        ></span>
                      </p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => scrollToTop()}
                      to="/blog"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <p>
                        Blog{' '}
                        <span
                          style={
                            pathname[1] === 'blog'
                              ? { background: 'white', width: '100%' }
                              : {}
                          }
                        ></span>
                      </p>
                    </Link>
                  </li>
                </ul>
                <button
                  className={styles.agendarReunion}
                  onClick={() => scrollNavigate('/agendar-reunion')}
                >
                  Agendar reunión
                </button>
              </>
            ) : (
              <>
                {/* NavBar solo para Planes */}
                <ul className={styles.menuHorizontal}>
                  <li>
                    <Link
                      onClick={() => scrollToTop()}
                      to="/productos/plan-standard"
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      <p>
                        Plan Standard{' '}
                        <span
                          style={
                            pathname[2] === 'plan-standard'
                              ? { background: 'white', width: '100%' }
                              : {}
                          }
                        ></span>
                      </p>
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={() => scrollToTop()}
                      to="/productos/plan-avanzado"
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      <p>
                        Plan Avanzado{' '}
                        <span
                          style={
                            pathname[2] === 'plan-avanzado'
                              ? { background: 'white', width: '100%' }
                              : {}
                          }
                        ></span>
                      </p>
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={() => scrollToTop()}
                      to="/productos/plan-prime"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <p>
                        Plan Prime{' '}
                        <span
                          style={
                            pathname[2] === 'plan-prime'
                              ? { background: 'white', width: '100%' }
                              : {}
                          }
                        ></span>
                      </p>
                    </Link>
                  </li>
                </ul>
                <button
                  className={styles.agendarReunion}
                  onClick={() => scrollNavigate('/agendar-reunion')}
                >
                  Agendar reunión
                </button>
              </>
            )
          ) : (
            <button
              className={styles.agendarReunion2}
              onClick={() => scrollNavigate('/')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M11.0685 17.8576L5.35547 12.1446L11.0685 6.43164M18.6695 12.1446H5.37247"
                  stroke="white"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{' '}
              Ir al inicio
            </button>
          )}
        </nav>
        {/* NavBarMobile */}
        {pathname[1] !== 'agendar-reunion' ? (
          <>
            <nav id="navBarMobile" className={styles.navBarMobile}>
              <img
                className={styles.logo}
                src={logoWhite}
                alt="Proyment-Icon"
                onClick={() => handleNavigates('/')}
              ></img>
              <label
                onClick={() => handleBurger(burgerSelect)}
                className={styles.burgerButton}
              >
                <span
                  className={`${styles.bar} ${
                    burgerSelect ? styles.actbar : ''
                  }`}
                ></span>
                <span
                  className={`${styles.bar} ${
                    burgerSelect ? styles.actbar : ''
                  }`}
                ></span>
                <span
                  className={`${styles.bar} ${
                    burgerSelect ? styles.actbar : ''
                  }`}
                ></span>
              </label>

              <ul
                className={`${styles.menuVertical} ${
                  burgerSelect ? styles.actMenuVertical : ''
                }`}
              >
                <li className={styles.verticalLI}>
                  <div className={styles.containerLi}>
                    <p
                      id="open1"
                      onClick={(e) => handleAccordionClick(e.target.id)}
                    >
                      Servicios
                    </p>
                    {activeAccordion === 'open1' ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        id="open1"
                        onClick={(e) => handleAccordionClick(e.target.id)}
                      >
                        <path
                          d="M3.7168 9.65514L8.00346 5.36914L12.2895 9.65514"
                          stroke="#000538"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        id="open1"
                        onClick={(e) => handleAccordionClick(e.target.id)}
                      >
                        <path
                          d="M12.2895 6.30078L8.00346 10.5874L3.7168 6.30078"
                          stroke="#000538"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                  <ul
                    className={`${styles.menuVerticalIn} ${
                      activeAccordion === 'open1'
                        ? styles.actMenuVerticalIn
                        : ''
                    }`}
                  >
                    <Link
                      onClick={() => handleDesactive()}
                      to="/servicios/servicios-integrales"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <li>Servicios integrales</li>
                    </Link>

                    <Link
                      onClick={() => handleDesactive()}
                      to="/servicios/marketing-digital"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <li>Marketing digital</li>
                    </Link>

                    <Link
                      onClick={() => handleDesactive()}
                      to="/servicios/analisis-datos"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <li>Analisis de datos</li>
                    </Link>

                    <Link
                      onClick={() => handleDesactive()}
                      to="/servicios/diseño-web"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <li>Diseño web UX/UI</li>
                    </Link>
                  </ul>
                </li>

                <li className={styles.verticalLI}>
                  <div className={styles.containerLi}>
                    <p
                      id="open2"
                      onClick={(e) => handleAccordionClick(e.target.id)}
                    >
                      Productos <span></span>
                    </p>

                    {activeAccordion === 'open2' ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        id="open2"
                        onClick={(e) => handleAccordionClick(e.target.id)}
                      >
                        <path
                          d="M3.7168 9.65514L8.00346 5.36914L12.2895 9.65514"
                          stroke="#000538"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        id="open2"
                        onClick={(e) => handleAccordionClick(e.target.id)}
                      >
                        <path
                          d="M12.2895 6.30078L8.00346 10.5874L3.7168 6.30078"
                          stroke="#000538"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                  <ul
                    className={`${styles.menuVerticalIn} ${
                      activeAccordion === 'open2'
                        ? styles.actMenuVerticalIn
                        : ''
                    }`}
                  >
                    <Link
                      onClick={() => handleDesactive()}
                      to="/productos/plan-standard"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <li>Plan Standard</li>
                    </Link>
                    <Link
                      onClick={() => handleDesactive()}
                      to="/productos/plan-avanzado"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <li>Plan Avanzado</li>
                    </Link>
                    <Link
                      onClick={() => handleDesactive()}
                      to="/productos/plan-prime"
                      style={{
                        textDecoration: 'none',

                        color: 'inherit',
                      }}
                    >
                      <li>Plan Prime</li>
                    </Link>
                  </ul>
                </li>

                <li className={styles.verticalLI}>
                  <Link
                    onClick={() => handleDesactive()}
                    to="/recursos"
                    style={{
                      textDecoration: 'none',

                      color: 'inherit',
                    }}
                  >
                    <p>
                      Recursos <span></span>
                    </p>
                  </Link>
                </li>

                <li className={styles.verticalLI}>
                  <Link
                    onClick={() => handleDesactive()}
                    to="/industrias"
                    style={{
                      textDecoration: 'none',

                      color: 'inherit',
                    }}
                  >
                    <p>
                      Industrias <span></span>
                    </p>
                  </Link>
                </li>

                <li className={styles.verticalLI}>
                  <Link
                    onClick={() => handleDesactive()}
                    to="/sobre-nosotros"
                    style={{
                      textDecoration: 'none',

                      color: 'inherit',
                    }}
                  >
                    <p>
                      Sobre Proyment <span></span>
                    </p>
                  </Link>
                </li>
                <li className={styles.verticalLI}>
                  <Link
                    to="/blog"
                    onClick={() => handleDesactive()}
                    style={{
                      textDecoration: 'none',

                      color: 'inherit',
                    }}
                  >
                    <p>
                      Blog <span></span>
                    </p>
                  </Link>
                </li>
                <button
                  className={styles.agendarReunion}
                  onClick={() => handleNavigates('/agendar-reunion')}
                >
                  Agendar reunión
                </button>
              </ul>
            </nav>
            {burgerSelect ? (
              <div
                className={styles.menuDesactive}
                onClick={() => handleDesactiveDiv()}
              ></div>
            ) : (
              ''
            )}
          </>
        ) : (
          <nav id="navBarMobile" className={styles.navBarMobileAgendarReunion}>
            <img
              className={styles.logo}
              src={logoWhite}
              alt="Proyment-Icon"
              onClick={() => handleNavigates('/')}
            ></img>
            <button
              className={styles.agendarReunion2}
              onClick={() => scrollNavigate('/')}
            >
              Ir al inicio
            </button>
          </nav>
        )}
      </section>
    </>
  );
}
