import style from './Transformation.module.scss';
import icon from './assets/vector-comillas.svg';
import fondoMobile from './assets/Mask group.png';
import fondo from './assets/Mask group (1).png';

function Transformation() {
  return (
    <section id="seccion1" className={style.container}>
      <div className={style.content}>
        <div className={style.boxContent}>
          <h1>
            Nuestras principales industrias con las que trabajamos en la
            actualidad
          </h1>
          <div className={style.itemsContent}>
            <img src={fondo} alt="" className={style.fondo} />
            <img src={fondoMobile} alt="" className={style.fondoMobile} />
            <div className={style.itemsBox1}>
              <div id={style.color}>
                <p>Rubro Industrial</p>
              </div>
              <div>
                <p>Rubro Petrolero</p>
              </div>
              <div id={style.color}>
                <p>Rubro Salud</p>
              </div>
              <div>
                <p>Rubro Inmobiliaria</p>
              </div>
            </div>
            <div className={style.itemsBox2}>
              <div>
                <p>Rubro Restaurantes</p>
              </div>
              <div id={style.color}>
                <p>Rubro Transportes</p>
              </div>
              <div>
                <p>Rubro Textil</p>
              </div>

              <div id={style.color}>
                <p>Rubro Educación</p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.textContent}>
          <div className={style.subTitle}>
            <img src={icon} alt="" />
            <h2>Creamos soluciones pensando en lo que tu empresa necesita</h2>
            <img src={icon} alt="" className={style.iconReverse} />
          </div>
          <p>
            En Proyment estamos dispuestos a conectar con nuevas industrias para
            fortalecer empresas de todo nivel de distintos sectores económicos
            con visión global y con un enfoque ágil de trabajo.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Transformation;
