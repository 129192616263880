import styles from './NosEnfocamos.module.scss';
import mapa from './assets/mapa1.png';

export default function NosEnfocamos() {
  return (
    <section id="NosEnfocamos" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h1>Nos enfocamos en tus resultados en corto plazo</h1>
          <h2>
            Alcanza resultados en base a estrategia con nuestra metodología
            estratégica aplicada a todos nuestros clientes.
          </h2>
        </div>
        <div className={styles.imgContainer}>
          <img src={mapa} alt="Mapa Mundial" loading='lazy'></img>
        </div>
      </div>
    </section>
  );
}
