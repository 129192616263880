import styles from './ConoceNuestrosPlanes.module.scss';
import Planes from './info';
import iconCheck from './assets/Component 32.svg';
import { useEffect } from 'react';
import scrollToTop from '../../../hooks/scrollToTop';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ConoceNuestrosPlanes() {
  const pathname = useLocation().pathname;
  const navigateTo = useNavigate();
  const navigate = (param) => {
    navigateTo(param);
    scrollToTop();
  };
  const navigateButton = (e) => {
    e.stopPropagation();
    navigateTo('/agendar-reunion');
    scrollToTop();
  };

  const renderCard = (content) => {
    const id = content.title.split(' ').join('-').toLowerCase();
    //console.log(`${'Conoce-'}${id}`);
    let title = '';

    if (id === 'plan-standard') {
      title =
        'Es un plan que busca potenciar, <span>posicionar y esquematizar tu empresa.</span>';
    } else if (id === 'plan-avanzado') {
      title =
        'Es un plan que busca incrementar tus ingresos a <span>través de la búsqueda de clientes de alto valor.</span>';
    } else if (id === 'plan-prime') {
      title =
        'Es un plan que busca el incremento de la rentabilidad a través del potenciamiento de tu imagen <span>corporativa, generación de clientes de alto valor y el incremento de la satisfacción del cliente.</span>';
    }
    return (
      /* <Link
        onClick={() => scrollToTop()}
        key={content.title}
        to={`/productos/${id}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
      >  */
      <div
        className={styles.card}
        key={content.title}
        id={`${'Conoce-'}${id}`}
        onClick={() => navigate(`/productos/${id}`)}
      >
        <h1 id={`inh1ConocePlanes`}>{content.title}</h1>
        <div className={styles.contentContainer}>
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <p className={styles.incluye}>Incluye:</p>
          <ul>
            {content.points.map((point) => (
              <li key={point}>
                <img src={iconCheck} alt="CheckIcon"></img>
                <p>{point}</p>
              </li>
            ))}
          </ul>
          <button
            className={styles.agendarReunion}
            onClick={(e) => navigateButton(e)}
          >
            Agendar reunión
          </button>
        </div>
      </div>
      /* </Link> */
    );
  };
  useEffect(() => {
    if (pathname.split('/')[2]) {
      const element = document.getElementById(
        `${'Conoce-'}${pathname.split('/')[2]}`,
      );
      const inh1 = element.querySelector('#inh1ConocePlanes');
      element.style.opacity = 1;
      element.style.border = '1px solid transparent';
      element.style.background =
        'linear-gradient(130deg, #000538 2.89%, #100267 100%)padding-box, linear-gradient(91deg, #8156ee 9.33%, #487ded 43.76%, #26afef 85.96%)border-box';
      //h1.style
      inh1.style.borderBottom = '1px solid transparent';
      inh1.style.background =
        'linear-gradient(91deg,#8156ee 9.33%,#487ded 43.76%,#26afef 85.96%)';
    }
  }, [pathname]);

  return (
    <section id="ConoceNuestrosPlanes" className={styles.section}>
      <div className={styles.container}>
        <h1 className={styles.title}>Conoce nuestros planes</h1>
        <div className={styles.cardsContainer}>
          {Planes[0].cards.map((card) => renderCard(card))}
        </div>
      </div>
    </section>
  );
}
