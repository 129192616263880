import ReactMarkdown from 'react-markdown';
import styles from './BlogPostRender.module.scss';
//import post from '../posts.markdown/LatransformaciondigitalenPymesPeruanas.md';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RecomendationsPosts from './Recomendations/Recomendations';
import QuieresEstarAlDia from './QuieresEstarAlDia/QuieresEstarAlDia';
//import Comentarios from './Comentarios/Comentarios';

export default function BlogPostRender(data) {
  //const file_name = 'Post1LatransformaciondigitalenPymesPeruanas.md';
  const file_name = data.filename;
  const metadata = data.metadata;
  
  //contenido del post .md
  const [post, setPost] = useState('');

  //Carga el post especifico en el estado 
  useEffect(() => {
    import(`../posts.markdown${file_name}`)
      .then((res) => {
        fetch(res.default)
          .then((res) => res.text())
          .then((res) => setPost(res))
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [file_name]);

  return (
    <section id={metadata.title} className={styles.container}>
      <Link to="/blog" className={styles.linkBlog}>
        BLog
        <span>&gt;</span>
        {metadata.title}
      </Link>
      <div className={styles.postComponentsContainer}>
        <div className={styles.postContainer}>
          <div className={styles.contentTitle}>
            <div className={styles.degrade}></div>
            <div className={styles.titlefloat}>
              <h1>{metadata.title}</h1>
              <h3>{metadata.subtitle}</h3>
            </div>
            <img src={metadata.img} alt={metadata.title}></img>
          </div>
          <div className={styles.markDownContainer}>
            <ReactMarkdown>{post}</ReactMarkdown>
          </div>
          {/* <Comentarios/> */}
        </div>
        <div>
          <QuieresEstarAlDia />
          <RecomendationsPosts tags={metadata.tags} postid={metadata.id} />
        </div>
      </div>
    </section>
  );
}
