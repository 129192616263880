import styles from './Section1.module.scss';
import image1 from './assets/1.png';
import image2 from './assets/2.png';
import image3 from './assets/3.png';
import image4 from './assets/4.png';
import image5 from './assets/5.png';
import image6 from './assets/6.png';

export default function Section1() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <h1>
          Nuestras principales <span>industrias</span> con las que trabajamos en
          la actualidad
        </h1>
        <div className={styles.infoContent}>
          <div className={styles.boxs}>
            <div className={styles.box}>
              <h3>Rubro Industrial</h3>
              <p>Empresas que se dedican a realizar materia prima.</p>
              <img src={image1} alt="Rubro Industrial" loading="lazy" />
            </div>
            <div className={styles.box}>
              <h3>Rubro Educación</h3>
              <p>Empresas que brindan excelencia en educación.</p>
              <img src={image2} alt="Rubro Educación" loading="lazy"/>
            </div>
          </div>
          <div className={styles.boxBig}>
            <h3>Rubro Restaurantes</h3>
            <p>Empresas que se dedican a preparar comida de alta calidad.</p>
            <img src={image3} alt="Rubro Restaurantes" loading="lazy"/>
          </div>
          <div className={styles.boxs}>
            <div className={styles.box}>
              <h3>Rubro Salud</h3>
              <p>Empresas que se enfocan a los análisis clínicos.</p>
              <img src={image4} alt="Rubro Salud" loading="lazy"/>
            </div>
            <div className={styles.box}>
              <h3>Rubro Textil</h3>
              <p>Empresas que se dedican a exportar prendas de alta costura.</p>
              <img src={image5} alt="Rubro Textil" loading="lazy"/>
            </div>
          </div>
          <div className={styles.boxBig}>
            <h3>Rubro Inmobiliaria</h3>
            <p>Empresas constructoras y desarrollos inmobiliarios.</p>
            <img src={image6} alt="Rubro Inmobiliaria" loading="lazy"/>
          </div>
        </div>
      </div>
    </section>
  );
}
