import image1 from "./assets/img1.png";
import image2 from "./assets/img2.png";
import image3 from "./assets/img3.png";
import image4 from "./assets/img4.png";
import image5 from "./assets/img5.png";
import image6 from "./assets/img6.png";


export const data = {
  card1: [
    {
      image: image1,
      title: 'Plantillas para crear tu MVP',
      paragraph:
        'Obtén esta guía poderosa que te ayudará definir un rumbo para el desarrollo del producto, incluir mejoras y ajustes para que su lanzamiento sea un éxito.',
    },
    {
      image: image2,
      title: 'Herramientas para la agilidad/SCRUM',
      paragraph:
        'Descubre las herramientas de agilidad y SCRUM para ayudar a tu equipo a estructurar y gestionar el trabajo.',
    },
    {
      image: image3,
      title: 'Investiga tu mercado',
      paragraph:
        'Conoce las formas de poder investigar tu mercado con las distintas herramientas para conectar al consumidor, al cliente y al público con el vendedor mediante la información recolectada.',
    },
  ],
  card2: [
    {
      image: image4,
      title: 'Desarrolla el arquetipo ideal',
      paragraph:
        'Conoce e identifica a grupos de usuarios que comparten ciertas características y comportamientos en relación a un producto o servicio, ya sea en un contexto de uso o de compra',
    },
    {
      image: image5,
      title: 'Templates de modelo de negocio',
      paragraph:
        'Utiliza las plantillas para mejorar tu negocio actual. Puedes lograr posicionarse mejor respecto a la competencia, ya que saben cómo identificar sus debilidades y fortalezas.',
    },
    {
      image: image6,
      title: 'Pasos para idear tu solución',
      paragraph:
        'Conoce las herramientas participativas, que se usan para identificar los problemas principales con sus causas y efectos, permitiendo a los planificadores de proyectos definir objetivos claros y prácticos',
    },
  ],
};
