import styles from './PaypalCheckout.module.scss';
import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

function PayPalCheckout() {
  const params = new URLSearchParams(window.location.search);
  const monto = params.get('monto');
  const price = monto ? monto : '200';
  return (
    <div className={styles.container}>
      <p>El monto es {price}$</p>
      <div className={styles.paypal}>
        <PayPalScriptProvider
          options={{
            'client-id':
              'Ad3xxPDz62DcHPA6Oho2xU82WuxjhNOS58IPc2c4d6QDDw2aZ9CJGYeIqnXQclPq9BaFldlqFJeXuLSn',
          }}
        >
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: price,
                    },
                  },
                ],
              });
            }}
            onApprove={async (data, actions) => {
              const details = await actions.order.capture();
              const name = details.payer.name.given_name;
              alert('Transaction completed by ' + name);
            }}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  );
}

export default PayPalCheckout;
