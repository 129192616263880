import styles from './DesafiosDeTodoNivel.module.scss';
import background from './assets/19sm.png';
import scrollToTop from '../../../hooks/scrollToTop';
import { useNavigate } from 'react-router-dom';

export default function DesafiosDeTodoNivel() {
  const navigateTo = useNavigate();
  const navigate = (param) => {
    navigateTo(param);
    scrollToTop();
  };
  return (
    <section id="Desafios de todo nivel" className={styles.container}>
      <div className={styles.contentContainer}>
        <span className={styles.line}></span>
        <h1>
          <span className={styles.spanLetterStyle}>
            Nos adaptamos a <span className={styles.inline}></span>
          </span>
          desafíos de todo nivel
        </h1>
        <p>
          Generamos valor a partir del ingenio humano, experiencia e innovación
          tecnológica, siendo un impulsor para optimizar las compañías con
          visión global.
        </p>
        <button
          className={styles.agendarReunion}
          onClick={() => navigate('/agendar-reunion')}
        >
          Agendar reunión{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M12.9565 7.22656L18.6695 12.9396L12.9565 18.6526M5.35547 12.9396H18.6525"
              stroke="white"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className={styles.img}>
        <div className={styles.degrade}></div>
        <img src={background} alt="Personas Trabajando en oficinas" loading='lazy'></img>
      </div>
    </section>
  );
}
