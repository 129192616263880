import React from 'react';
import style from './LandingHome.module.scss';

import Heroimage from './assets/01 lg.png';
import HeroMobile from './assets/03 sm.png';
import vector from './assets/Vector-53-1.svg';
import vector2 from './assets/Vector 53.svg';
import imgFloat from './assets/imgFloat.svg';
import imgFloat2 from './assets/imgFloat3.svg';
import { Link } from 'react-router-dom';
import scrollToTop from '../../../hooks/scrollToTop';
import scrollToNextSection from '../../../hooks/scrollToNextSection';

function LandingHome() {

  return (
    <section className={style.container}>
      <div className={style.contentText}>
        <div className={style.contentTitle}>
          <h1>
            <img
              src={vector}
              alt="vector"
              className={style.vector}
              id="contentLandingEstatico"
            />{' '}
            <br />
            <span>
              Transformando
              <img src={vector2} alt="vector" className={style.vector2} />
            </span>{' '}
            <br /> <span>desafíos en</span> nuevas <br /> oportunidades
          </h1>
        </div>
        <p className={style.paragraph}>
          En proyment, nuestro desafío es potenciar empresas en la dirección de
          resolución de problemas a través de servicios integrales.
        </p>

        <div className={style.contentBtn}>
          <Link to={'/agendar-reunion'} onClick={scrollToTop} style={{ textDecoration: 'none' }}>
            <button className={style.btn}>
              Agendar reunión <i className="ri-arrow-right-line"></i>
            </button>
          </Link>
          {/* <Link to={'/'} onClick={scrollToTop} style={{ textDecoration: 'none' }}> */}
            <button className={style.btn2} onClick={()=>scrollToNextSection('Seccion1-Logos', 777)}>
              Conocer más <i className="ri-arrow-right-line"></i>
            </button>
          {/* </Link> */}
        </div>
      </div>

      <div className={style.contentImg}>
        <img src={Heroimage} alt="Heroimage.png" className={style.heroImg} loading="lazy"/>
        <img src={HeroMobile} alt="Heroimage" className={style.heroImgMobile} loading="lazy"/>
        <img src={imgFloat} alt="imgFloat-from" className={style.floatImg1} loading="lazy"/>
        <img src={imgFloat2} alt="imgFloat-from" className={style.floatImg2} loading="lazy"/>
      </div>
    </section>
  );
}
export default LandingHome;
