import React from 'react';
import styles from './Section3.module.scss';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import degrade from '../../Blog.posts.feature/posts.markdown/assets/overlay2.png';
import logo from '../../Blog.posts.feature/posts.markdown/assets/logo_white 2.png';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import scrollToTop from '../../../hooks/scrollToTop';

export default function ReusableThreeCard({ data, title }) {
  return (
    <>
      <h1 className={styles.title}>{title}</h1>
      <Swiper
        modules={[Autoplay]}
        //loop={true}
        autoplay={{
          delay: 3000,
          /* pauseOnMouseEnter: false,
              disableOnInteraction: false, */
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          375: {
            slidesPerView: 1.05,
            spaceBetween: 10,
          },
          425: {
            slidesPerView: 1.05,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1.5,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className={styles.slideContent}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Link
              to={`/blog/${item.metadaBlog.tags}/${item.title.replace(/ /g,'-')}`}
              style={{ textDecoration: 'none' }}
              onClick={scrollToTop}
            >
              <div className={styles.card}>
                <div className={styles.imgContent}>
                  <img src={item.img} alt={item.title} className={styles.img} loading="lazy"/>
                  <img
                    src={degrade}
                    alt={item.title}
                    className={styles.degrade}
                  />
                  <img src={logo} alt={item.title} className={styles.logo} loading="lazy"/>
                </div>
                <div className={styles.text}>
                  <h2>{item.title}</h2>
                  <p>{item.subtitle}</p>
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
