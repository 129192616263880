import React from 'react';
import styles from './LideresEnLatinoamerica.module.scss';
import { data } from './Data';
//import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/pagination';
import './Lideres.scss';

const LideresEnLatinoamerica = () => {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.titleContent}>
          <h1>Líderes en Latinoamérica nos</h1>
          <span>Recomiendan</span>
        </div>

        <div className={styles.swiperContent}>
          <div className={styles.slideContent}>
            <Swiper
              modules={[Pagination, Autoplay]}
              autoplay={{
                delay: 4000,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
              }}
              pagination={{
                el: '.lideres-pagination',
                clickable: true,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                375: {
                  slidesPerView: 1.06,
                  spaceBetween: -5,
                },
                385: {
                  slidesPerView: 1.08,
                  spaceBetween: -5,
                },
                395: {
                  slidesPerView: 1.10,
                  spaceBetween: -5,
                },
                405: {
                  slidesPerView: 1.12,
                  spaceBetween: -5,
                },
                425: {
                  slidesPerView: 1.2,
                  spaceBetween: 0,
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1340: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1440: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              {data.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.card}>
                    <img src={item.image} alt={item.name} loading='lazy' />
                    <div className={styles.text}>
                      <h3>{item.name}</h3>
                      <h4>{item.titulo}</h4>
                      <p>{item.frase}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className=" lideres-pagination" />
        </div>
      </div>
    </section>
  );
};

export default LideresEnLatinoamerica;
