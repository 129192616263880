import card1IMG from './assets/img1.png';
import card2IMG from './assets/img2.png';
import card3IMG from './assets/img3.png';
import card4IMG from './assets/img4.png';

import tagImg1 from './assets/Post4IMG1.png';
import tagImg2 from './assets/Post5IMG1.png';
import tagImg3 from './assets/Post6IMG1.png';
import tagImg4 from './assets/Post7IMG1.png';
import tagImg5 from './assets/Post8IMG1.png';
import tagImg6 from './assets/Post9IMG1.png';
import tagImg7 from './assets/Post10IMG1.png';
import tagImg8 from './assets/Post11IMG1.png';
import tagImg9 from './assets/Post12IMG1.png';

import post1IMG1mob from './assets/img 10.png';
import post2IMG1mob from './assets/img 11.png';
import post3IMG1mob from './assets/img 12.png';
import post1IMG1carrousel from './assets/img 04.png';
import post2IMG1carrousel from './assets/img 05.png';
import post3IMG1carrousel from './assets/img 06.png';
import post1IMG1 from './assets/Post1IMG1.jpg';
import post2IMG1 from './assets/Post2IMG1.png';
import post3IMG1 from './assets/Post3IMG1.png';
import post4IMG1 from './assets/Post4IMG1.png';
import post5IMG1 from './assets/Post5IMG1.png';
import post6IMG1 from './assets/Post6IMG1.png';
import post7IMG1 from './assets/Post7IMG1.png';
import post8IMG1 from './assets/Post8IMG1.png';
import post9IMG1 from './assets/Post9IMG1.png';
import post10IMG1 from './assets/Post10IMG1.png';
import post11IMG1 from './assets/Post11IMG1.png';
import post12IMG1 from './assets/Post12IMG1.png';

const postsList = [
  {
    id: 1,
    antetitle: 'El 95% considera un impacto positivo en su negocio.',
    title: 'La transformación digital en Pymes peruanas',
    subtitle: 'La adopción de la tecnología se ha convertido en una prioridad.',
    img: post1IMG1,
    imgCarrousel: post1IMG1carrousel,
    imageMobile: post1IMG1mob,
    metadaBlog: {
      subtitle: 'El impacto de la transformación digital en los negocios.',
      tags: 'transformacionDigital',
      route: '/Post1LatransformaciondigitalenPymesPeruanas.md',
    },
  },
  {
    id: 2,
    antetitle: 'El año de los proyectos.',
    title: 'Proyectos tecnológicos que marcarán el 2023',
    subtitle:
      'Desde innovaciones en Inteligencia Artificial hasta avance en energía renovable.',
    img: post2IMG1,
    imgCarrousel: post2IMG1carrousel,
    imageMobile: post2IMG1mob,
    metadaBlog: {
      subtitle: 'El año de los proyectos.',
      tags: 'proyectos',
      route: '/Post2Losproyectostecnologicosquemarcaranel2023.md',
    },
  },
  {
    id: 3,
    antetitle: '',
    title: 'Los datos de calidad',
    subtitle:
      'Principal desafío que existen en el Marketing y la Investigación.',
    img: post3IMG1,
    imgCarrousel: post3IMG1carrousel,
    imageMobile: post3IMG1mob,
    metadaBlog: {
      subtitle: 'El año de los proyectos.',
      tags: 'calidad',
      route: '/Post3Losdatosdecalidad.md',
    },
  },
  {
    id: 4,
    antetitle: '',
    title: 'Silicon Valley Bank',
    subtitle: 'Los errores que llevaron a la quiebra al banco de las startups.',
    img: post4IMG1,
    metadaBlog: {
      subtitle: '',
      tags: 'finanzas',
      route: '/Post4Siliconvalleybank.md',
    },
  },
  {
    id: 5,
    antetitle: '',
    title: 'Finanzas personales',
    subtitle: '6 pasos para organizarse y planificar tus objetivos.',
    img: post5IMG1,
    metadaBlog: {
      subtitle: '',
      tags: 'finanzas',
      route: '/Post5Laimportanciadelasfinanzas.md',
    },
  },
  {
    id: 6,
    antetitle: '',
    title: 'Pronósticos económicos para 2023',
    subtitle: 'Tendencias y factores.',
    img: post6IMG1,
    metadaBlog: {
      subtitle: '',
      tags: 'finanzas',
      route: '/Post6Pronosticoseconomicospara2023.md',
    },
  },
  {
    id: 7,
    antetitle: '',
    title: 'Recursos Humanos 4.0',
    subtitle: '8 beneficios para tu organización.',
    img: post7IMG1,
    metadaBlog: {
      subtitle: '',
      tags: 'recursosHumanos',
      route: '/Post7Recursoshumanos4.0.md',
    },
  },
  {
    id: 8,
    antetitle: '',
    title: 'Los retos para Recursos Humanos en 2023',
    subtitle: '¿Cuáles serán las tendencias para las áreas de RR.HH?',
    img: post8IMG1,
    metadaBlog: {
      subtitle: '',
      tags: 'recursosHumanos',
      route: '/Post8Losretospararecursoshumanosen2023.md',
    },
  },
  {
    id: 9,
    antetitle: '',
    title: 'El gran reto de RR.HH',
    subtitle: 'Reclutar talento en Linkedin.',
    img: post9IMG1,
    metadaBlog: {
      subtitle: '',
      tags: 'recursosHumanos',
      route: '/Post9Elgranretoderr.hh.md',
    },
  },
  {
    id: 10,
    antetitle: '',
    title: 'La innovación digital',
    subtitle: 'Tendencias en este 2023.',
    img: post10IMG1,
    metadaBlog: {
      subtitle: 'Tendencias en este 2023.',
      tags: 'transformacionDigital',
      route: '/Post10Lainnovaciondigital.md',
    },
  },
  {
    id: 11,
    antetitle: '',
    title: 'Transformación digital empresarial',
    subtitle:
      '¿Cuáles son los escenarios que marcarán la transformación digital empresarial?',
    img: post11IMG1,
    metadaBlog: {
      subtitle:
        '¿Cuáles son los escenarios que marcarán la transformación digital empresarial?',
      tags: 'transformacionDigital',
      route: '/Post11Transformaciondigitalempresarial.md',
    },
  },
  {
    id: 12,
    antetitle: '',
    title: 'Transformación digital',
    subtitle: 'Tendencias de la transformación digital en el marketing.',
    img: post12IMG1,
    metadaBlog: {
      subtitle: 'Tendencias de la transformación digital en el marketing.',
      tags: 'transformacionDigital',
      route: '/Post12Transformaciondigital.md',
    },
  },
];

const cards = [
  {
    id: 12,
    antetitle: '',
    title: 'Transformación digital',
    subtitle: 'Tendencias de la transformación digital en el marketing.',
    img: card1IMG,
    metadaBlog: {
      subtitle: 'Tendencias de la transformación digital en el marketing.',
      tags: 'transformacionDigital',
      route: '/Post12Transformaciondigital.md',
    },
  },
  {
    id: 7,
    antetitle: '',
    title: 'Recursos Humanos 4.0',
    subtitle: '8 beneficios de RR.HH 4.0 en tu organización.',
    img: card2IMG,
    metadaBlog: {
      subtitle: '',
      tags: 'recursosHumanos',
      route: '/Post7Recursoshumanos4.0.md',
    },
  },
  {
    id: 11,
    antetitle: '',
    title: 'Transformación digital empresarial',
    subtitle:
      '¿Cuáles son los escenarios que marcarán la transformación digital empresarial?',
    img: card3IMG,
    metadaBlog: {
      subtitle:
        '¿Cuáles son los escenarios que marcarán la transformación digital empresarial?',
      tags: 'transformacionDigital',
      route: '/Post11Transformaciondigitalempresarial.md',
    },
  },
  {
    id: 4,
    antetitle: '',
    title: 'Silicon Valley Bank',
    subtitle: 'Los errores que llevaron a la quiebra al banco de las startups.',
    img: card4IMG,
    metadaBlog: {
      subtitle: '',
      tags: 'finanzas',
      route: '/Post4Siliconvalleybank.md',
    },
  },
];

const tags = {
  finanzas: [
    {
      id: 4,
      antetitle: '',
      title: 'Silicon Valley Bank',
      subtitle:
        'Los errores que llevaron a la quiebra al banco de las startups.',
      //img: './assets/Post3IMG1.png',
      img: tagImg1,
      metadaBlog: {
        subtitle: '',
        tags: 'finanzas',
        route: './Post4Siliconvalleybank.md',
      },
    },
    {
      id: 5,
      antetitle: '',
      title: 'Finanzas personales',
      subtitle: '6 pasos para organizarse y planificar tus objetivos.',
      //img: './assets/Post5IMG1.png',
      img: tagImg2,
      metadaBlog: {
        subtitle: '',
        tags: 'finanzas',
        route: './Post5Laimportanciadelasfinanzas.md',
      },
    },
    {
      id: 6,
      antetitle: '',
      title: 'Pronósticos económicos para 2023',
      subtitle: 'Tendencias y factores.',
      //img: './assets/Post6IMG1.png',
      img: tagImg3,
      metadaBlog: {
        subtitle: '',
        tags: 'finanzas',
        route: './Post6Pronosticoseconomicospara2023.md',
      },
    },
  ],
  recursosHumanos: [
    {
      id: 7,
      antetitle: '',
      title: 'Recursos Humanos 4.0',
      subtitle: '8 beneficios para tu organización.',
      //img: './assets/Post7IMG1.png',
      img: tagImg4,
      metadaBlog: {
        subtitle: '',
        tags: 'recursosHumanos',
        route: './Post7Recursoshumanos4.0.md',
      },
    },
    {
      id: 8,
      antetitle: '',
      title: 'Los retos para Recursos Humanos en 2023',
      subtitle: '¿Cuáles serán las tendencias para las áreas de RR.HH?',
      //img: './assets/Post8IMG1.png',
      img: tagImg5,
      metadaBlog: {
        subtitle: '',
        tags: 'recursosHumanos',
        route: './Post8Losretospararecursoshumanosen2023.md',
      },
    },
    {
      id: 9,
      antetitle: '',
      title: 'El gran reto de RR.HH',
      subtitle: 'Reclutar talento en Linkedin.',
      //img: './assets/Post9IMG1.png',
      img: tagImg6,
      metadaBlog: {
        subtitle: '',
        tags: 'recursosHumanos',
        route: './Post9Elgranretoderr.hh.md',
      },
    },
  ],
  transformacionDigital: [
    {
      id: 1,
      antetitle: 'El 95% considera un impacto positivo en su negocio.',
      title: 'La transformación digital en Pymes peruanas',
      subtitle:
        'La adopción de la tecnología se ha convertido en una prioridad.',
      img: post1IMG1,
      imgCarrousel: post1IMG1carrousel,
      imageMobile: post1IMG1mob,
      metadaBlog: {
        subtitle: 'El impacto de la transformación digital en los negocios.',
        tags: 'transformacionDigital',
        route: '/Post1LatransformaciondigitalenPymesPeruanas.md',
      },
    },
    {
      id: 10,
      antetitle: '',
      title: 'La innovación digital',
      subtitle: 'Tendencias en este 2023.',
      //img: './assets/Post10IMG1.png',
      img: tagImg7,
      metadaBlog: {
        subtitle: 'Tendencias en este 2023.',
        tags: 'transformacionDigital',
        route: '/Post10Lainnovaciondigital.md',
      },
    },
    {
      id: 11,
      antetitle: '',
      title: 'Transformación digital empresarial',
      subtitle:
        '¿Cuáles son los escenarios que marcarán la transformación digital empresarial?',
      //img: './assets/Post11IMG1.png',
      img: tagImg8,
      metadaBlog: {
        subtitle:
          '¿Cuáles son los escenarios que marcarán la transformación digital empresarial?',
        tags: 'transformacionDigital',
        route: '/Post11Transformaciondigitalempresarial.md',
      },
    },
    {
      id: 12,
      antetitle: '',
      title: 'Transformación digital',
      subtitle: 'Tendencias de la transformación digital en el marketing.',
      //img: './assets/Post12IMG1.png',
      img: tagImg9,
      metadaBlog: {
        subtitle: 'Tendencias de la transformación digital en el marketing.',
        tags: 'transformacionDigital',
        route: '/Post12Transformaciondigital.md',
      },
    },
  ],
  proyectos: [
    {
      id: 2,
      antetitle: 'El año de los proyectos.',
      title: 'Proyectos tecnológicos que marcarán el 2023',
      subtitle:
        'Desde innovaciones en Inteligencia Artificial hasta avance en energía renovable.',
      img: post2IMG1,
      imgCarrousel: post2IMG1carrousel,
      imageMobile: post2IMG1mob,
      metadaBlog: {
        subtitle: 'El año de los proyectos.',
        tags: 'proyectos',
        route: '/Post2Losproyectostecnologicosquemarcaranel2023.md',
      },
    },
  ],
  calidad: [
    {
      id: 3,
      antetitle: '',
      title: 'Los datos de calidad',
      subtitle:
        'Principal desafío que existen en el Marketing y la Investigación.',
      img: './assets/Post3IMG1.png',
      metadaBlog: {
        subtitle: 'El año de los proyectos.',
        tags: 'calidad',
        route: './Post3Losdatosdecalidad.md',
      },
    },
  ],
};

export { tags, postsList, cards };
