import React, { useState, useEffect } from 'react';
import styles from './AgendarReunion.module.scss';
import { data } from './Data';
import flotanteIzq from './assets/grafico-flotante.png';
import flotanteDer from './assets/grafico-flotante2.png';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form'; // Import the useForm hook
import scrollToTop from '../../hooks/scrollToTop';
import { countryCodes } from './CodigosDeAreas';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import 'sweetalert2/src/sweetalert2.scss'

const { servicios, planes } = data;

export default function AgendarReunion() {
  const [selectedCountryCode, setSelectedCountryCode] = useState('+51');
  const [step, setStep] = useState(1);
  const [services, setServices] = useState([]);
  const [formData, setFormData] = useState({
    services: [],
    nombre: '',
    cargo: '',
    email: '',
    telefono: '',
    texto: '',
  });

  //console.log(formData)

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm(); // Initialize useForm

  // Add a flag to track form validity
  const [isFormValid, setIsFormValid] = useState(false);

  const handleServiceChange = (service) => {
    if (services.includes(service)) {
      setServices(services.filter((s) => s !== service));
    } else {
      setServices([...services, service]);
    }
  };


  const handleData = () => {
    // Define las variables para tu servicio, plantilla y clave pública de EmailJS
    const YOUR_SERVICE_ID = 'service_i5zyw9b';
    const YOUR_TEMPLATE_ID = 'template_h0mic5l';
    const YOUR_PUBLIC_KEY = 'TugkRYSEt18eqU0gu';
  
    // Prepara los datos del formulario para enviar
    const templateParams = {
      services: formData.services.join(', '), // Convierte los servicios en una cadena
      nombre: formData.nombre,
      cargo: formData.cargo,
      email: formData.email,
      telefono: formData.telefono,
      texto: formData.texto,
    };
  
    // Envía el formulario utilizando EmailJS
    emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_PUBLIC_KEY)
      .then((response) => {
        console.log('Formulario enviado con éxito:', response);
        // Puedes agregar aquí lógica adicional, como mostrar un mensaje de éxito al usuario.
      })
      .catch((error) => {
        //console.error('Error al enviar el formulario:', error);
        // Puedes manejar errores aquí, como mostrar un mensaje de error al usuario.
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al enviar el formulario!',
          //footer: '<a href="">Why do I have this issue?</a>'
        })
      });
  };

  const handleContinue = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const onSubmit = (data) => {
    // Handle form submission logic here
    const telefono = selectedCountryCode + ' ' + data.telefono;
    const cargo = data.cargo;
    const email = data.email;
    const nombre = data.nombre;

    handleContinue();
    setFormData({
      ...formData,
      services: services,
      nombre: nombre,
      email: email,
      cargo: cargo,
      telefono: telefono,
    });
  };

  useEffect(() => {
    // Update isFormValid based on form validity
    setIsFormValid(isDirty && isValid);
  }, [isDirty, isValid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  

  
  //console.log(selectedCountryCode);

  const renderProgressCheckboxes = () => {
    if (step === 4) {
      return (
        <div className={styles.progress}>
          <label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="38"
              viewBox="0 0 39 38"
              fill="none"
            >
              <path
                d="M19.5 38C29.9937 38 38.5 29.4937 38.5 19C38.5 8.5063 29.9937 0 19.5 0C9.0063 0 0.5 8.5063 0.5 19C0.5 29.4937 9.0063 38 19.5 38Z"
                fill="#6F4EF6"
              />
              <path
                d="M10.9004 20.2295L15.8138 25.1429L28.0974 12.8594"
                stroke="white"
                strokeWidth="3" // Corrección aquí
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </label>
        </div>
      );
    } else {
      return (
        <div className={styles.progress}>
          <label>
            <input type="checkbox" checked={step >= 1} readOnly />
            <span />
            <input type="checkbox" checked={step >= 2} readOnly />
            <span />
            <input type="checkbox" checked={step >= 3} readOnly />
          </label>
          <div>
            <p>Nuestros servicios</p>
            <p>Datos personales</p>
            <p>Sobre tu proyecto</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={`${styles.container} ${step === 4 ? styles.container2 : ''}`}
    >
      <div className={`${styles.content}`}>
        <img src={flotanteIzq} alt="" className={styles.flotanteIzq} />
        <img src={flotanteDer} alt="" className={styles.flotanteDer} />
        {step < 4 && <h1 id="contentLandingEstatico">Agendar reunión</h1>}
        {renderProgressCheckboxes()}
        {step === 1 && (
          <div className={styles.step}>
            <h2 id="contentLandingEstatico">
              ¿Qué servicio deseas contratar o son de tu interés?
            </h2>
            <div className={styles.checkboxContent}>
              <div className={styles.services}>
                {servicios.map((item, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      name={item.name}
                      checked={services.includes(item.name)}
                      onChange={() => handleServiceChange(item.name)}
                    />
                    {item.name}
                  </label>
                ))}
              </div>

              <div className={styles.services}>
                {planes.map((item, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      name={item.plan}
                      checked={services.includes(item.plan)}
                      onChange={() => handleServiceChange(item.plan)}
                    />
                    {item.plan}
                  </label>
                ))}
              </div>
            </div>

            <div className={styles.buttons}>
              <button
                onClick={handleContinue}
                disabled={services.length === 0}
                className={styles.btnNext}
              >
                Continuar
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className={styles.step}>
            <h2 id="contentLandingEstatico">Completa tus datos</h2>
            <p>Tus datos nos sirven para comunicarnos contigo.</p>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <div className={styles.formContent}>
                <div className={styles.column}>
                  <div className={styles.formGroup}>
                    <label>Nombres y apellidos:</label>
                    <input
                      placeholder="Ingrese su nombre"
                      type="text"
                      name="nombre"
                      {...register('nombre', {
                        required: true,
                        pattern: /^[A-Za-z\s]+$/i, // Allow spaces (\s)
                        maxLength: 20,
                      })}
                    />
                    {errors.nombre?.type === 'required' && (
                      <p role="alert">First name is required</p>
                    )}
                  </div>
                  <div className={styles.formGroup}>
                    <label>Cargo:</label>
                    <input
                      placeholder="Escribe aquí tu cargo"
                      type="text"
                      name="cargo"
                      {...register('cargo', {
                        required: true,
                        pattern: /^[A-Za-z\s]+$/i, // Allow spaces (\s)
                        maxLength: 30,
                      })}
                    />
                  </div>
                </div>
                <div className={styles.column}>
                  <div className={styles.formGroup}>
                    <label>E-mail corporativo:</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Ingrese su E-mail"
                      {...register('email', {
                        required: true,
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                      })}
                    />
                  </div>
                  <div className={styles.formGroup}>
                    <label>Número de contacto:</label>
                    <div className={styles.inputContent}>
                      <select
                        name="countryCode"
                        onChange={(e) => setSelectedCountryCode(e.target.value)}
                        value={selectedCountryCode}
                        className={styles.selectCode}
                      >
                        {/* <option value=""> (+51)</option> */}
                        {countryCodes.map((country) => (
                          <option key={country.code} value={country.code}>
                            ({country.code}) {country.name}
                          </option>
                        ))}
                      </select>
                      <input
                        id={styles.inputPhone}
                        type="number"
                        name="telefono"
                        placeholder="Ingrese su número de contacto aquí"
                        {...register('telefono', {
                          required: true,
                          pattern: /^[0-9]+$/, // Only allow numeric digits
                          min: 0, // Establece un valor mínimo de 0
                          max: 999999999, // Establece un valor máximo de 9999999999 (10 dígitos)
                        })}
                        onInput={(e) => {
                          if (e.target.value.length > 9) {
                            e.target.value = e.target.value.slice(0, 9); // Limita la longitud a 10 dígitos
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttons}>
                <button onClick={handleBack} className={styles.btnPrev}>
                  Volver Atrás
                </button>
                <button
                  type="submit" // Add type="submit" to the button
                  onClick={() => {
                    handleSubmit();
                  }}
                  disabled={!isFormValid} // Enable/disable based on form validity
                  className={styles.btnNext}
                >
                  Continuar
                </button>
              </div>
            </form>
          </div>
        )}

        {step === 3 && (
          <div className={styles.step}>
            <h2>Cuéntanos sobre tu proyecto</h2>
            <p>¿Qué resultados esperas conseguir?</p>
            <textarea
              placeholder="Escribe aqui..."
              name="texto"
              value={formData.texto}
              onChange={handleInputChange}
            />
            <div className={styles.buttons}>
              <button onClick={handleBack} className={styles.btnPrev}>
                Volver Atrás
              </button>
              <button
                onClick={() => {
                  handleContinue();
                  handleData();
                }}
                className={styles.btnNext}
              >
                Continuar
              </button>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className={`${styles.step} ${styles.step4}`}>
            <h2 id={styles.title}>
              ¡Gracias por ponerte en contacto con nosotros!
            </h2>
            <p>
              Hemos recibido tú información. Nos estaremos contactando contigo a
              la brevedad.
            </p>
            <div className={styles.buttons}>
              <Link to="/" onClick={scrollToTop}>
                <button className={styles.btnNext}>Ir al Inicio</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
