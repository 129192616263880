import styles from './Section1.module.scss';
import fondo from './Rectangle 4975.png';
import fondoMobile from './Rectangle 4975 (1).png';

export default function Section1() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <img src={fondo} alt="" className={styles.fondo}/>
        <img src={fondoMobile} alt="" className={styles.fondoMobile} />
        <div className={styles.text}>
          <h1 id='contentLandingEstatico'>Somos Proyment</h1>
          <p>
            Somos una consultora empresarial que tiene como objetivo transformar
            o potenciar empresas a través de un marco de trabajo especializado y
            altamente flexible con el objetivo de cumplir y superar desafíos
            empresariales a través de una gama de servicios integrales.
          </p>
        </div>
        <span><i className="ri-arrow-down-s-line"></i></span>
      </div>
    </section>
  );
}
