import { useEffect, useState } from 'react';
import styles from './QuieresEstarAlDia.module.scss';
import { tags } from '../../posts.markdown/dataPosts';
import { Link, useLocation } from 'react-router-dom';

export default function QuieresEstarAlDia() {
  const pathname = useLocation().pathname.split('/')[2];
  const [filter, setFilter] = useState();
  useEffect(() => {
    //console.log(pathname, 'esto es pathname');
    //console.log(filter, ' esto es filter');
    setFilter(pathname);
  }, [pathname, filter]);
  const tagsDisplay = [
    'Finanzas',
    //'Comercial',
    'Proyectos',
    //'Procesos',
    'Calidad',
    //'Operaciones',
    //'Estrategias',
    'Recursos Humanos',
    'Transformación Digital',
  ];
  function quitarTildes(texto) {
    const tildes = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
    };

    return texto.replace(
      /[áéíóúÁÉÍÓÚ]/g,
      (letraConTilde) => tildes[letraConTilde],
    );
  }
  function tagCreator(tag) {
    //funcion que quita los tildes ej: Transformación Digital ---> Transformacion Digital
    const tagFiltered = quitarTildes(tag);
    //chequea si el string tiene espacios para aplicar cierta logica respectivamente
    const settag = tagFiltered.includes(' ')
      ? //si tiene espacio lo separa por la mitad un string de dos palabras y pone en minisculas la primera para luego juntarlas
        //ej: Transformacion Digital ---> transformacionDigital
        tagFiltered.split(' ')[0].toLowerCase() + tagFiltered.split(' ')[1]
      : //si este es el caso simplemente pone en minuscula la palabra
        //ej Calidad ---> calidad
        tagFiltered.toLowerCase();
    return settag;
  }
  function checkTag(filter, tag) {
    //llamamos a la function
    const settag = tagCreator(tag);
    // Por ultimo comprobamos si la palabra es igual al filtro obtenido desde la ruta
    return settag === filter ? true : false;
  }
  function handleRedirect(tag) {
    //path={`/blog/${post.metadaBlog.tags}/${post.title}`} redirigir a esta ruta
    //obtenemos limpia la tag a donde ir
    const filtertag = tagCreator(tag);
    //randomIndex da un post al azar dentro de la categoria dada por tag
    const randomIndex = Math.floor(Math.random() * tags[filtertag].length);
    const postTitle = tags[filtertag][randomIndex].title;
    return `/blog/${filtertag}/${postTitle.replace(/ /g, '-')}`;
  }
  //tenemos esos filtros, debo filtrar por tag
  //y dentro de la tag actualizar el post al azar
  return (
    <section id="¿Quieres estar al día?" className={styles.container}>
      <div className={styles.contentContainer}>
        <h1>¿Quieres estar al día?</h1>
        <h2>Tenemos los mejores contenidos, novedosos e innovadores.</h2>
        <div className={styles.cards}>
          {tagsDisplay.map((tag, index) => (
            <Link
              key={index}
              to={handleRedirect(tag)}
              style={{ textDecoration: 'none' }}
            >
              <p
                /* onClick={() => {
                alert(tagCreator(tag));
              }} */
                key={index}
                className={checkTag(filter, tag) ? styles.active : ''}
              >
                {tag}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
