

export const data = {
    servicios:[
        {name: "Servicios Integrales"},
        {name: "Servicio de marketing digital"},
        {name: "Servicio de Analisis de datos"},
        {name: "Servicio de Diseño web UI/UX"},
    ],
    planes:[
        {plan: "Plan Standard"},
        {plan: "Plan Avanzado"},
        {plan: "Plan Prime"},
    ]
}