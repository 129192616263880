import image1 from "./assets/07.png";
import image2 from "./assets/08.png";
import image3 from "./assets/09.png";
import image4 from "./assets/10.png";

export const data = [
    {
        image:image1,
        title: "Servicios Integrales",
        paragraph: "Transformación del negocio",
        href: "/servicios/servicios-integrales",
    },
    {
        image:image2,
        title: "Marketing Digital",
        paragraph: "Estrategias digitales",
        href: "/servicios/marketing-digital",
    },
    {
        image:image3,
        title: "Análisis de Datos",
        paragraph: "Automatización analítica",
        href: "/servicios/analisis-datos",
    },
    {
        image:image4,
        title: "Diseño web UI/UX",
        paragraph: "Diseño e interfaz",
        href: "/servicios/diseño-web",
    },
]