import img1 from './imgAdnCards/04.png';
import img2 from './imgAdnCards/02.jpeg';
import img3 from './imgAdnCards/03.jpeg';
import img4 from './imgAdnCards/05.png';

import icons1 from './icons/01.png';
import icons2 from './icons/02.png';
import icons3 from './icons/03.png';
import icons4 from './icons/04.png';

export const infoADNCards = [
  {
    id: 1,
    icons: icons1,
    title: 'Agilidad',
    description:
      'Impulsamos la disrupción para lograr mejoras sustanciales en el rendimiento, acelerando el cambio empresarial.',
    img: img1,
    ADN: true,
  },
  {
    id: 2,
    icons: icons2,
    title: 'Auto-optimización',
    img: img2,
    description:
      'Rediseñamos procesos a través de la mejora continua para resolver desafíos complejos, presentes y futuros en la estructura empresarial.',
    ADN: true,
  },
  {
    id: 3,
    icons: icons3,
    img: img3,
    title: 'Automatización',
    description:
      'Implementamos software para la realización de tareas repetitivas para reemplazo del trabajo manual en un entorno operativo estándar.',
    ADN: true,
  },
  {
    id: 4,
    icons: icons4,

    title: 'Efectividad',
    description:
      'Alcanzamos los objetivos que son trazados como si fuesen nuestras propias metas empresariales.',
    img: img4,
    ADN: true,
  },
];
