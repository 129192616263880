import React from 'react';
import styles from './CadaAreaTieneUnIndicador.module.scss';

const CardC = ({ info }) => {
  return (
    <div className={styles.cardResposive}>
      <h4>{info.Title}</h4>
      <p>{info.descipcion}</p>

      <p className={styles.pLinks}>
        {' '}
        <span className={styles.icono}>
          <i className="ri-check-line"></i>{' '}
        </span>
        {info.punto1}
      </p>
      <p className={styles.pLinks}>
        {' '}
        <span className={styles.icono}>
          <i className="ri-check-line"></i>{' '}
        </span>
        {info.punto2}
      </p>
      <p className={styles.pLinks}>
        {' '}
        <span className={styles.icono}>
          <i className="ri-check-line"></i>{' '}
        </span>
        {info.punto3}
      </p>
      <p className={styles.pLinks}>
        {' '}
        <span className={styles.icono}>
          <i className="ri-check-line"></i>{' '}
        </span>
        {info.punto4}
      </p>
    </div>
  );
};

export default CardC;
