import Page from '../../features/Home.feature/Page';
import React from 'react';

/* import Page2 from "../../features/Servicios.feature/page2";

import { Route, Routes } from "react-router-dom"; */

export default function Home() {
  return (
    <>
      <Page />
    </>
  );
}
