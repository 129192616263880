import styles from './Button.module.scss';
import { Link } from 'react-router-dom';
import scrollToTop from '../../../hooks/scrollToTop';

export default function FormButton({ planes = false }) {
  return (
    <>
      <Link to="/agendar-reunion" className={styles.container} onClick={()=> scrollToTop()} >
        <button className={planes ? styles.btnplanes : styles.btn}>
          Agendar reunión
        </button>
      </Link>
    </>
  );
}
