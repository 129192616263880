import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import styles from './Section2.module.scss';
//import { Link } from 'react-router-dom';
//import scrollToTop from '../../../hooks/scrollToTop';

import 'swiper/scss';
import 'swiper/scss/pagination';
//import '../ADNProyment/Carrousel.scss';

export default function SwiperSection2({ data }) {
  return (
    <div className={styles.contentSwiper}>
      <div className={styles.slideContent}>
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ el: '.services-pagination', clickable: true }}
          className={styles.carrousel}
          autoplay={{
            delay: 4000,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            375: {
              slidesPerView: 1.1,
              spaceBetween: 0,
            },
            400: {
              slidesPerView: 1.15,
              spaceBetween: 0,
            },
            425: {
              slidesPerView: 1.2,
              spaceBetween: 0,
            },
            450: {
              slidesPerView: 1.25,
              spaceBetween: 0,
            },
            475: {
              slidesPerView: 1.35,
              spaceBetween: 0,
            },
            500: {
              slidesPerView: 1.4,
              spaceBetween: 0,
            },
            525: {
              slidesPerView: 1.45,
              spaceBetween: 0,
            },
            550: {
              slidesPerView: 1.5,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            800: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            910: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1220: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1440: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={styles.card}>
                <img src={item.image} alt={item.title} loading="lazy" />

                <div className={styles.text}>
                  <h4>{item.anteTitle}</h4>
                  <h3>{item.title}</h3>
                  <p>{item.paragraph}</p>
                </div>
                {/* <Link to={'/industrias'} className={styles.btn} onClick={scrollToTop}>
                  Ver caso<i className="ri-arrow-right-line"></i>
                </Link> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="services-pagination" />
    </div>
  );
}
