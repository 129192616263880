import React, { useState } from 'react';
import styles from './BannerNotification.module.scss';
import useSuperponen from '../../hooks/useEffectSuperponen';
import { useLocation, useNavigate } from 'react-router-dom';

/* //Date
import { format, addDays } from 'date-fns';
import { es } from 'date-fns/locale';

function obtenerFechaEnEspanol(diasAdelante) {
  const fechaActual = new Date();
  const fechaFutura = addDays(fechaActual, diasAdelante);

  // Formatear la fecha en español
  const fechaFormateada = format(fechaFutura, 'd ', { locale: es });
  const mesFormateado = format(fechaFutura, 'MMMM', { locale: es });

  return `${fechaFormateada} de ${
    mesFormateado.charAt(0).toUpperCase() + mesFormateado.slice(1)
  }`;
}

// Ejemplo de uso: Obtener la fecha 5 días en adelante
const diasAdelante = 5;
const fechaEnEspanol = obtenerFechaEnEspanol(diasAdelante); */


export default function BannerNotification() {
  const [isAlertActive, SetAlertState] = useState(true);
  const superponen = useSuperponen('alertAbsoluto');
  const navigate = useNavigate();
  const pathname = useLocation().pathname.split('/');

  return (
    <section
      id="alertAbsoluto"
      className={styles.banner}
      style={
        isAlertActive === true && pathname[1] !== 'agendar-reunion'
          ? isAlertActive === true && !superponen
            ? { display: 'flex' }
            : {
                //display: 'none'
                position: 'fixed',
                zIndex: '60',
                top: '-40px',
              }
          : { display: 'none' }
      }
    >
      <div className={styles.content}>
        <svg
          className={styles.svgBanner}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.99976 15.9998L15.9998 7.99976M7.94611 4.24947L6.2517 4.23679C5.13545 4.22843 4.22843 5.13545 4.23679 6.2517L4.24947 7.94611C4.25352 8.48691 4.03838 9.0063 3.65311 9.38584L2.44602 10.5751C1.6508 11.3585 1.6508 12.6412 2.44602 13.4246L3.65311 14.6137C4.03838 14.9933 4.25352 15.5127 4.24947 16.0535L4.23679 17.7479C4.22843 18.8641 5.13545 19.7711 6.2517 19.7628L7.94611 19.7501C8.48691 19.7461 9.0063 19.9612 9.38584 20.3465L10.5751 21.5536C11.3585 22.3488 12.6412 22.3488 13.4246 21.5536L14.6137 20.3465C14.9933 19.9612 15.5127 19.7461 16.0535 19.7501L17.7479 19.7628C18.8641 19.7711 19.7711 18.8641 19.7628 17.7479L19.7501 16.0535C19.7461 15.5127 19.9612 14.9933 20.3465 14.6137L21.5536 13.4246C22.3488 12.6412 22.3488 11.3585 21.5536 10.5751L20.3465 9.38584C19.9612 9.0063 19.7461 8.48691 19.7501 7.94611L19.7628 6.2517C19.7711 5.13545 18.8641 4.22843 17.7479 4.23679L16.0535 4.24947C15.5127 4.25352 14.9933 4.03838 14.6137 3.65311L13.4246 2.44602C12.6412 1.6508 11.3584 1.6508 10.5751 2.44602L9.38584 3.65311C9.0063 4.03838 8.48691 4.25352 7.94611 4.24947ZM10.1665 8.99992C10.1665 9.7363 9.56948 10.3333 8.8331 10.3333C8.09672 10.3333 7.49977 9.7363 7.49977 8.99992C7.49977 8.26354 8.09672 7.66659 8.8331 7.66659C9.56948 7.66659 10.1665 8.26354 10.1665 8.99992ZM16.4998 14.9998C16.4998 15.7362 15.9029 16.3331 15.1665 16.3331C14.4301 16.3331 13.8331 15.7362 13.8331 14.9998C13.8331 14.2634 14.4301 13.6665 15.1665 13.6665C15.9029 13.6665 16.4998 14.2634 16.4998 14.9998Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h1>
          ¡Aprovecha hasta el
          <span> 30% OFF </span>
          en nuestros productos y comienza a rentabilizar tu empresa con
          nosotros!
          <span> Solo hasta el 15 de Febrero.</span>
          {/* <span> Solo hasta el {fechaEnEspanol}</span> */}
        </h1>
        <button onClick={() => navigate('/agendar-reunion')}>
          <p>¡Empieza Ahora!</p>
        </button>
      </div>
      <button
        className={styles.closeBanner}
        onClick={() => SetAlertState(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M4.44531 4.43164L11.556 11.541M4.44531 11.541L11.556 4.43164"
            stroke="white"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </section>
  );
}
