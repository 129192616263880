import React, { useEffect } from 'react';
//import { InlineWidget } from 'react-calendly';
import styles from './CalendlyWidget.module.scss';
import './CalendlyWidget.scss';

const CalendlyWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    /* <div className={styles.container}>
      <InlineWidget url="https://calendly.com/proyment/30min?hide_event_type_details=1&hide_gdpr_banner=1&background_color=e6fefa&text_color=000538" />
  </div> */
    <div
      className={`${styles.container} calendly-inline-widget`}
      data-url="https://calendly.com/proyment/30min?hide_event_type_details=1&hide_gdpr_banner=1&background_color=f3fbfb&text_color=000538"
    ></div>
  );
};

export default CalendlyWidget;
