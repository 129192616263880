import styles from './NuestroProducto.module.scss';
import data from './info';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/pagination';
import './CarrouselNP.scss';

export default function NuestroProducto() {
  return (
    <section id="NuestroProducto" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h1>
            Con nuestro producto descubrirás
            <span> ...</span>
          </h1>
          <h2>
            Nos enfocaremos en lo que más importa, tu facturación y
            rentabilidad.
          </h2>
        </div>
        <div className={styles.swiperContainer}>
          <div className={styles.swiperContent}>
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ el: '.NP-pagination1', clickable: true }}
              className={styles.carrousel}
              autoplay={{
                delay: 4000,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
              }}
              breakpoints={{
                360: { slidesPerView: 1.1 },
                400: { slidesPerView: 1.2 },
                440: { slidesPerView: 1.3 },
                510: { slidesPerView: 1.6 },
                600: { slidesPerView: 1.8 },
                760: { slidesPerView: 2.3 },
                1000: { slidesPerView: 3 },
                1100: { slidesPerView: 3.4 },
                1300: { slidesPerView: 4 },
              }}
            >
              {data.slice(0, 4).map((card, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.card1} key={index}>
                    <img src={card.icon} alt={index}></img>
                    <p> {card.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="NP-pagination1" />
          </div>
          <div className={styles.swiperContent}>
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ el: '.NP-pagination2', clickable: true }}
              className={styles.carrousel}
              autoplay={{
                delay: 4000,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
              }}
              breakpoints={{
                360: { slidesPerView: 1.1 },
                400: { slidesPerView: 1.2 },
                440: { slidesPerView: 1.3 },
                510: { slidesPerView: 1.6 },
                600: { slidesPerView: 1.8 },
                760: { slidesPerView: 2.3 },
                1000: { slidesPerView: 3 },
                1100: { slidesPerView: 3.4 },
                1300: { slidesPerView: 4 },
              }}
            >
              {data.slice(4, 8).map((card, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.card2} key={index}>
                    <img src={card.icon} alt={index}></img>
                    <p> {card.text}</p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="NP-pagination2" />
          </div>
        </div>
      </div>
    </section>
  );
}
