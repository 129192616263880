import Register from '../../../Components/Register/Register';
import style from './LandingPageMarkentig.module.scss';
import img from './assets/degradado.svg';
import { Link } from 'react-router-dom';
import scrollToTop from '../../../hooks/scrollToTop';
import scrollToNextSection from '../../../hooks/scrollToNextSection';


export default function LandingPageMarkentig() {
  return (
    <div className={style.container}>
      <div className={style.degrade}>
        <img src={img} alt="Marqueting-digital" />
      </div>
      <div className={style.containerInfo}>
        <h4>Servicio de marketing 360°</h4>
        <h2>Innova, Transforma, Alcanza.</h2>

        <div className={style.divBtn}>
            <button className={style.BTNConocerMas} onClick={()=>scrollToNextSection('seccion1')}>Conocer más</button>
          <Link to="/agendar-reunion" onClick={()=>scrollToTop()}>
            <button className={style.BTNAgendarReunion}>Agendar reunión</button>
          </Link>
        </div>
      </div>

      <div className={style.containerForm}>
        <Register />
      </div>
    </div>
  );
}
