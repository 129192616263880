import React from 'react';
import styles from './PorqueProyment.module.scss';
import image1 from './assets/14.png';
import image2 from './assets/15.png';
import image2m from './assets/15 sm.png';
import vectorImg from './assets/Vector 53.png';
import avatar1 from './assets/01.png';
import avatar2 from './assets/02.png';
import avatar3 from './assets/03.png';
import avatar4 from './assets/04.png';
import grafic1 from './assets/graphic float 04.svg';
import grafic2 from './assets/graphic float 03.svg';

const PorqueProyment = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.contentTextTop}>
            <img src={vectorImg} alt="vector" className={styles.vector} />
            <h3>¿POR QUÉ PROYMENT?</h3>
            <h2>Tranformamos un cambio positivo y duradero.</h2>
            <p>
              Nuestro enfoque empresarial es desarrollar estrategias de
              innovación desde una perspectiva global y alineadas
              especificamente a la industria a tocar, para hacer que el cambio
              sea una realidad sostenible en el tiempo.
            </p>
          </div>

          <div className={styles.imgContent}>
            <img src={image1} alt="Persona en una computadora" className={styles.image1} loading='lazy'/>
            <img src={avatar1} alt="Avatares de personas" className={styles.avatar1} loading='lazy'/>
            <img src={avatar2} alt="Avatares de personas" className={styles.avatar2} loading='lazy'/>
            <img src={avatar3} alt="Avatares de personas" className={styles.avatar3} loading='lazy'/>
            <img src={avatar4} alt="Avatares de personas" className={styles.avatar4} loading='lazy'/>
          </div>
        </div>
        <div className={styles.contentBottom}>
          <div className={styles.imgContentBottom}>
            <img src={image2} alt="Persona en una computadora" className={styles.image2} loading='lazy'/>
            <img src={image2m} alt="Persona en una computadora" className={styles.image2m} loading='lazy'/>
            <img src={grafic1} alt="Graficos de Estadistica" className={styles.grafic1} loading='lazy'/>
            <img src={grafic2} alt="Graficos de Estadistica" className={styles.grafic2} loading='lazy'/>
          </div>
          <div className={styles.contentTextBottom}>
            <img src={vectorImg} alt="vector" className={styles.vector} />
            <h3>¿POR QUÉ PROYMENT?</h3>
            <h2>El cambio comienza con la estrategia 360°.</h2>
            <p>
              Reimagina y restablece las nuevas prioridades estratégicas del
              negocio que permitirán construir modelos y culturas operativas
              resilientes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PorqueProyment;
