import React, { useState, useEffect, useCallback } from 'react';
import styles from './Section2.module.scss';
import { Link } from 'react-router-dom';
import { postsList } from '../../Blog.posts.feature/posts.markdown/dataPosts';
import scrollToTop from '../../../hooks/scrollToTop';

const images = postsList.slice(0, 3)
//path={`/blog/${post.metadaBlog.tags}/${post.title}`}

const Section2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  // Define una función para avanzar al siguiente slide
  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, []);

  // Define una función para retroceder al slide anterior
  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    if (isMobileView) {
        const interval = setInterval(nextSlide, 5000);
        return () => clearInterval(interval);
      }

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileView, nextSlide]);

  return (
    <section className={styles.carousel}>
      {images.slice(0, 3).map((image, index) => (
        <div
          key={index}
          className={`${styles.slide} ${
            index === currentIndex ? styles.active : ''
          }`}
        >
          <div className={styles.contentText}>
            <div className={styles.contentTitle}>
              <h2>{image.antetitle}</h2>
              <h1>{image.title}</h1>
            </div>
            <p className={styles.paragraph}>{image.subtitle}</p>
            <Link to={`/blog/${image.metadaBlog.tags}/${image.title.replace(/ /g, '-')}`} style={{ textDecoration: 'none' }} onClick={scrollToTop}>
              <button className={styles.btn}>
                Más información{' '}
                {!isMobileView && <i className="ri-arrow-right-line"></i>}
              </button>
            </Link>
          </div>
          <img className={styles.image} src={image.imgCarrousel} alt={image.title} loading="lazy"/>

          <img
            className={styles.imageMobile}
            src={image.imageMobile}
            alt={image.title}
            loading="lazy"
          />
        </div>
      ))}
      {!isMobileView && (
        <div className={styles.navigation}>
          <button className={styles.prevButton} onClick={prevSlide}>
            <i className="ri-arrow-left-s-line"></i>
          </button>
          <button className={styles.nextButton} onClick={nextSlide}>
            <i className="ri-arrow-right-s-line"></i>
          </button>
        </div>
      )}
      {!isMobileView && (
        <div className={styles.pagination}>
          {images.map((_, index) => (
            <span
              key={index}
              className={`${styles.dot} ${
                index === currentIndex ? styles.activeDot : ''
              }`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      )}
    </section>
  );
};

export default Section2;
