import LandingRecursos from "./LandingRecursos/LandingRecursos";
import Section1 from "./Section1/Section1";

export default function Page() {
  return (
      <>
      <LandingRecursos/>
      <Section1/>
      </>
  )
}
