export default function scrollToNextSection(section, position) {
  const pathname = window.location.pathname;

  const servicios = pathname.split('/')[1];

  // Find the element with the ID 'Seccion1'
  const nextSection = document.getElementById(section);
  // Check if the element with ID 'Seccion1' exists
  if (nextSection) {
    console.log(nextSection.offsetTop, 'esto es offset');
    console.log(position, 'esto es position');
    console.log(servicios, 'esto es servicios');
    // Calculate the position to scroll to (74 pixels before the top of the section)
    const positionN = position ? position : 777;
    const scrollToPosition =
      servicios === 'servicios'
        ? nextSection.offsetTop - 74
        : nextSection.offsetTop === positionN
        ? nextSection.offsetTop - 74
        : nextSection.offsetTop - 114;

    //4 servicios sin banner osea 40px menos // offset siempre son iguales
    //las demas tenemos que restar cuando hay banner 40px // offset no es igual

    // Scroll to the calculated position smoothly
    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth',
    });
  }
}
