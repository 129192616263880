import image1 from "./assets/bg.png";
import image2 from "./assets/1.png";
import image3 from "./assets/2.png";
import image4 from "./assets/3.png";
import image5 from "./assets/4.png";
import image6 from "./assets/5.png";
import image7 from "./assets/6.png";
import image8 from "./assets/bgm.png";
import grafic from "./assets/caso de éxito.png";
import effect from "./assets/effect.png";


export const data={
    box1:{
        image: image1,
        imageM: image8,
        grafic: grafic,
        effect: effect,
        anteTitle: "Servicios Integrales",
        title: "Caso de éxito a Tom Gutié",
        paragraph: "Solución a: Proyment fue requerido para la definición del modelo de gestión de Innovación más adecuado para la empresa y acompañar el desarrollo de iniciativas concretas de nuevos negocios."
    },
    box2:[
        {
            image: image2,
            anteTitle: "Servicios Integrales",
            title: "Caso de éxito a SP digital",
            paragraph: "Solución a: Problemas de rentabilidad, utilización de activos y productividad en su filial.",
        },
        {
            image: image3,
            anteTitle: "Servicios de Marketing",
            title: "Caso de éxito a SP digital",
            paragraph: "Solución a: Problemas de falta de posicionamiento en un entorno de baja competencia.",
        },
        {
            image: image4,
            anteTitle: "Servicios de Marketing",
            title: "Caso de éxito a ABN8 Trading",
            paragraph: "Solución a: Problemas de falta de prospectos en los canales B2C y B2B.",
        }
    ],
    box3:[
        {
            image: image5,
            anteTitle: "Servicios Integrales",
            title: "Caso de éxito a Adecor Proyectos",
            paragraph: "Solución a: Problemas de productividad en el personal y áreas de la empresa inmobiliaria, haciendo que los entregables de proyectos se retracen.",
        },
        {
            image: image6,
            anteTitle: "Servicios de Marketing",
            title: "Caso de éxito a BAKAR PERÚ",
            paragraph: "Solución a: Problemas de baia cantidad de prospectos y disminución de la conversión en el canal digital.",
        },
        {
            image: image7,
            anteTitle: "Servicios de UX",
            title: "Caso de éxito a SPACEOS",
            paragraph: "Solución a: Problemas de comunicación, baja conversión y pérdidas de prospectos en el canal digital.",
        }
    ]
}