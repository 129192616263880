import React from 'react';
import Services from './Services/Services';
import ADN from './ADNProyment/ADN';
import OPT from './OPTProyment/OPTProyment';
import PorqueProyment from './PorqueProyment/PorqueProyment';
import Transformation from './Transformation/Transformation';
import LideresEnLatinoamerica from './lideresEnLatinoamerica/LideresEnLatinoamerica';
import Seccion1Logos from './Seccion1Logos/Seccion1Logos';
import LandingHome from './LandingHome/LandingHome';
import ProductosATuMedida from './ProductosTuMedida/ProductosTuMedida';
import DesafiosDeTodoNivel from './DesafiosDeTodoNivel/DesafiosDeTodoNivel';
import PotenciaCompañias from './100Compañias/100Compañias';

export default function Page() {
  return (
    <>
      <LandingHome/>
      <Seccion1Logos/>
      <ADN></ADN>
      <OPT></OPT>
      <Services/>
      <ProductosATuMedida/>
      <PorqueProyment/>
      <Transformation/>
      <PotenciaCompañias/>
      <LideresEnLatinoamerica/>
      <DesafiosDeTodoNivel/>
    </>
  );
}
