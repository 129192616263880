import styles from './ProductosTuMedida.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import img1 from './assets/11.png';
import img2 from './assets/12.png';
import img3 from './assets/13.jpeg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import '../ADNProyment/Carrousel.scss';
import scrollToTop from '../../../hooks/scrollToTop';

export default function ProductosATuMedida() {
  const navigateTo = useNavigate();
  const navigate = (param) => {
    navigateTo(param);
    scrollToTop();
  };

  return (
    <section id="ServiciosATuMedida" className={styles.container}>
      <div className={styles.contentContainer}>
        <span className={styles.line}></span>
        <h1>Productos a tu medida </h1>
        <p>
          ¿Lo mejor? Puedes elegir nuestros nuevos productos o los que más se
          adapten a tu empresa
        </p>
        <button
          className={styles.agendarReunion}
          onClick={() => navigate('/agendar-reunion')}
        >
          Agendar reunión{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M12.9565 7.22656L18.6695 12.9396L12.9565 18.6526M5.35547 12.9396H18.6525"
              stroke="white"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className={styles.swiperContent}>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{ el: '.PTM-pagination', clickable: true }}
          navigation={{
            prevEl: '.PTM-button-prev',
            nextEl: '.PTM-button-next',
          }}
          autoplay={{
            delay: 4000,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
          className={styles.carrousel}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            375: {
              slidesPerView: 1.07,
              spaceBetween: 10,
            },
            420: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            475: {
              slidesPerView: 1.3,
              spaceBetween: 10,
            },
            540: {
              slidesPerView: 1.4,
              spaceBetween: 10,
            },
            760: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            1020: {
              slidesPerView: 1.6,
              spaceBetween: 10,
            },
            1290: {
              slidesPerView: 2.35,
              spaceBetween: 10,
            },
          }}
        >
          {cards.map((card, index) => (
            <SwiperSlide key={index}>
              <div key={card.title} className={styles.card}>
                <img alt={card.title} src={card.img} loading='lazy'></img>
                <div className={styles.cardContentContainer}>
                  <h1>{card.title}</h1>
                  <p>{card.text}</p>
                  <Link
                    to={card.link}
                    onClick={() => scrollToTop()}
                    className={styles.btn}
                  >
                    Ver más
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="PTM-pagination" />
        <div className="PTM-button-prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M15.2152 19.3698L8.78516 12.9398L15.2152 6.50977"
              stroke="#000538"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="PTM-button-next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M9.50391 6.00977L15.9339 12.4398L9.50391 18.8698"
              stroke="#000538"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}

const cards = [
  {
    img: img1,
    title: 'Plan Standard',
    text: 'Plan que busca potenciar, posicionar y esquematizar tu empresa.',
    link: '/productos/plan-standard',
  },
  {
    img: img2,
    title: 'Plan Avanzado',
    text: 'Plan que busca incrementar tus ingresos a través de clientes de alto valor.',
    link: '/productos/plan-avanzado',
  },
  {
    img: img3,
    title: ' Plan Prime',
    text: 'Es un plan que busca el incremento de rentabilidad a través de la generación de clientes de alto valor y el incremento de la satisfacción del cliente.',
    link: '/productos/plan-prime',
  },
];
