import styles from './100Compañias.module.scss';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context('../Seccion1Logos/logos', false, /\.(jpg|jpeg|png|gif|svg)$/),
);

export default function PotenciaCompañias() {
  return (
    <section
      id="+100 Compañias se potenciaron con nuestros servicios"
      className={styles.container}
    >
      <div className={styles.contentContainer}>
        <h1>
          +100 compañías se potenciaron con <span> nuestros servicios</span>
        </h1>
      </div>
      <div className={styles.slider}>
        <div className={styles.degradeRight}></div>
        <div className={styles.degradeLeft}></div>
        <div className={styles.slideTrack}>
          {images.map((image, index) => (
            <div key={index} className={styles.slide}>
              <img src={image} alt="Icons" loading="lazy" />
            </div>
          ))}
          {images.map((image, index) => (
            <div key={index} className={styles.slide}>
              <img src={image} alt={image.default} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
