import styles from './Section2.module.scss';
import SwiperSection2 from './SwiperSection2';
import { data } from './Data';
//import { Link } from 'react-router-dom';
//import { useState } from 'react';
//import scrollToTop from '../../../hooks/scrollToTop';

const { box1, box2, box3 } = data;
export default function Section2() {
  //const [isOpen, setIsOpen] = useState(false);
  //const [selectedOption, setSelectedOption] = useState(null);

  /* const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  }; */

  //const options = ['Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum'];
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.titleContent}>
          <h1>
            Explora nuestros <span>inspiradores casos de éxito</span>
          </h1>
          <p>Nuestros logros con marcas que buscan potenciar sus resultados</p>
        </div>
        <div className={styles.boxs}>
          <div className={styles.box1}>
            {/* <div className={styles.select}>
              <h3>Industria</h3>
              <div className={styles['custom-selector']}>
                <div
                  className={`${styles['select-button']} ${
                    isOpen ? styles['open'] : ''
                  }`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  Todos{' '}
                  {isOpen ? (
                    <i className="ri-arrow-up-s-line"></i>
                  ) : (
                    <i className="ri-arrow-down-s-line"></i>
                  )}
                </div>
                {isOpen && (
                  <div className={styles['options']}>
                    {options.map((option, index) => (
                      <div
                        key={index}
                        className={`${styles['option']} ${
                          selectedOption === option ? styles['selected'] : ''
                        }`}
                        onClick={() => handleOptionClick(option)}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div> */}
            <div className={styles.box1Box}>
              <img src={box1.image} alt="" className={styles.image} loading='lazy'/>
              <img src={box1.imageM} alt="" className={styles.imageM} loading='lazy'/>
              <img src={box1.effect} alt="" className={styles.effect} loading='lazy'/>
              <img src={box1.grafic} alt="" className={styles.grafic} loading='lazy'/>
              <div className={styles.box1BoxText}>
                <h4>{box1.anteTitle}</h4>
                <h3>{box1.title}</h3>
                <p>{box1.paragraph}</p>
                {/* <Link to={'/industrias'} onClick={scrollToTop} className={styles.btnBox1}>
                  Ver más <i className="ri-arrow-right-line"></i>
                </Link> */}
              </div>
            </div>
          </div>
          <div className={styles.slidesContainer}>
            <SwiperSection2 data={box2} />
            <SwiperSection2 data={box3} />
          </div>
        </div>
      </div>
    </section>
  );
}
