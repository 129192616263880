import styles from './Section3.module.scss';
import { tags } from '../../Blog.posts.feature/posts.markdown/dataPosts';
import ReusableThreeCard from './ReusableThreeCard';

export default function Section3() {
  const { finanzas, transformacionDigital, recursosHumanos } = tags;
  const transformacion = transformacionDigital.slice(1,4)

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <ReusableThreeCard data={finanzas} title={"Finanzas"} />
        <ReusableThreeCard data={recursosHumanos} title={"Recursos humanos"} />
        <ReusableThreeCard data={transformacion} title={"Transformación Digital"}/>
      </div>
    </section>
  );
}
