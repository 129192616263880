import React from 'react';
import './Logos.scss';

/* import img1 from '../../Home.feature/CompaniesFriendly/assets/Component 12.png';
import img2 from '../../Home.feature/CompaniesFriendly/assets/Component 11.png';
import img3 from '../../Home.feature/CompaniesFriendly/assets/Component 14.png';
import img4 from '../../Home.feature/CompaniesFriendly/assets/Component 15.png';
import img5 from '../../Home.feature/CompaniesFriendly/assets/Component 17.png';
import img6 from '../../Home.feature/CompaniesFriendly/assets/Component 19.png';
import img7 from '../../Home.feature/CompaniesFriendly/assets/Component 20.png';
import img8 from '../../Home.feature/CompaniesFriendly/assets/Component 21.png';
 */
function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    '../../Home.feature/Seccion1Logos/logos',
    false,
    /\.(jpg|jpeg|png|gif|svg)$/,
  ),
);

function Logos() {
  return (
    <div className="logos--container">
      <div className="logos--container-text">
        <h2>
          Cada vez más empresas usan el servicio de Proyment para generar
          clientes potenciales de calidad
        </h2>
      </div>

      <div className="logos--container-imgs">
        <div className="slider-for-logos">
          <div className="slide-track-for-logos">
            {images.slice(0, 4).map((image, index) => (
              <div key={index} className="slide-for-logos">
                <img src={image} alt="logo" className="imgCompanies" />
              </div>
            ))}
            {images.slice(0, 4).map((image, index) => (
              <div key={index} className="slide-for-logos">
                <img src={image} alt="logo" className="imgCompanies" />
              </div>
            ))}
            {images.slice(0, 4).map((image, index) => (
              <div key={index} className="slide-for-logos">
                <img src={image} alt="logo" className="imgCompanies" />
              </div>
            ))}
          </div>
        </div>

        <div className="slider-for-logos">
          <div className="slide-track-for-logos2">
            {images.slice(5, 8).map((image, index) => (
              <div key={index} className="slide-for-logos">
                <img src={image} alt="logo" className="imgCompanies" />
              </div>
            ))}
            {images.slice(5, 8).map((image, index) => (
              <div key={index} className="slide-for-logos">
                <img src={image} alt="logo" className="imgCompanies" />
              </div>
            ))}
            {images.slice(5, 8).map((image, index) => (
              <div key={index} className="slide-for-logos">
                <img src={image} alt="logo" className="imgCompanies" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logos;
