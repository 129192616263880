const cards = [
  {
    title: 'PLAN STANDARD',
    points: ['Branding.', 'Redes sociales.', 'Consultoría estratégica.'],
  },
  {
    title: 'PLAN AVANZADO',
    points: [
      'Imagen Empresarial.',
      'Redes sociales.',
      'Consultoría estratégica.',
      'Crecimiento de prospectos y clientes.',
    ],
  },
  {
    title: 'PLAN PRIME',
    points: [
      'Imagen Empresarial.',
      'Marketing.',
      'Diseño web.',
      'Desarrollo web.',
      'Consultorías de escalamiento.',
      'Crecimiento de clientes y escala de valor.',
    ],
  },
];
const Planes = [
  {
    title: 'Potenciamos,<span> posicionar y esquematizar</span> tu empresa.',
    cards: cards,
  },
  {
    title:
      'Incrementamos tus ingresos a través de la<span> búsqueda de clientes de alto valor.</span>',
    cards: cards,
  },
  {
    title:
      'Aumentamos la rentabilidad a través del incremento<span> de la satisfacción del cliente.</span>',
    cards: cards,
  },
];

export default Planes;
