import React, { useState, useEffect } from 'react';
import styles from './Landing.module.scss';
import img1 from './assets/img 01.png';
import img2 from './assets/img 02.png';
import img3 from './assets/img 03.png';
import img6 from './assets/img 07.png';
import img5 from './assets/img 08.png';
import img4 from './assets/img 09.png';
import vector from './assets/Vector-53-1.svg';
import scrollToNextSection from '../../../hooks/scrollToNextSection';

const images = [
  {
    title: 'Título 1',
    image: img1,
    imageMobile: img4,
  },
  {
    title: 'Título 2',
    image: img2,
    imageMobile: img5,
  },
  {
    title: 'Título 3',
    image: img3,
    imageMobile: img6,
  },
];

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section className={styles.carousel}>
      {images.map((image, index) => (
        <div
          key={index}
          className={`${styles.slide} ${
            index === currentIndex ? styles.active : ''
          }`}
        >
          <div className={styles.contentText}>
            <div className={styles.contentTitle}>
              <h1>
                <img
                  src={vector}
                  alt="vector"
                  className={styles.vector}
                  id="contentLandingEstatico"
                />{' '}
                <br />
                <span>La mejor información</span> <br /> actualizada la
                encuentras <br /> en Proyment.
              </h1>
            </div>
            <p className={styles.paragraph}>
              Llegaste al lugar indicado para estar informado de tendencias e
              innovación
            </p>

            {/* <Link
              to={'/agendar-reunion'}
              onClick={scrollToTop}
              style={{ textDecoration: 'none' }}
            > */}
            <button
              className={styles.btn}
              onClick={() => scrollToNextSection('seccion1', 679)}
            >
              Ver todas las noticias <i className="ri-arrow-right-line"></i>
            </button>
            {/*   </Link> */}
          </div>
          <img
            className={styles.image}
            src={image.image}
            alt={image.title}
            loading="lazy"
          />
          <img
            className={styles.imageMobile}
            src={image.imageMobile}
            alt={image.title}
            loading="lazy"
          />
        </div>
      ))}
    </section>
  );
};

export default LandingPage;
