import React from 'react';
import { useForm } from 'react-hook-form';
import style from './Register.module.scss';
import './Prueba.scss';
import emailjs from '@emailjs/browser';
import { Swal } from 'sweetalert2/dist/sweetalert2';

const Register = ({ info }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm();

  let isEmpty = Object.entries(errors).length === 0;

  let boton =
    isEmpty === true && formState.isDirty === true && formState.isValid
      ? {
          backgroundColor: '#6F4EF5',
        }
      : {
          backgroundColor: '#BAADFB',
        };

  const onSubmit = (data, e) => {
    handleData(data);
    console.log(data);
  };
  const handleData = (data) => {
    // Define las variables para tu servicio, plantilla y clave pública de EmailJS
    const YOUR_SERVICE_ID = 'service_i5zyw9b';
    const YOUR_TEMPLATE_ID = 'template_h0mic5l';
    const YOUR_PUBLIC_KEY = 'TugkRYSEt18eqU0gu';

    // Prepara los datos del formulario para enviar
    const templateParams = {
      services: data.servicio,
      nombre: data.nombre,
      //cargo: formData.cargo,
      email: data.email,
      telefono: data.telefono,
      //texto: formData.texto,
    };

    // Envía el formulario utilizando EmailJS
    emailjs
      .send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_PUBLIC_KEY)
      .then((response) => {
        console.log('Formulario enviado con éxito:', response);
        // Puedes agregar aquí lógica adicional, como mostrar un mensaje de éxito al usuario.
      })
      .catch((error) => {
        //console.error('Error al enviar el formulario:', error);
        // Puedes manejar errores aquí, como mostrar un mensaje de error al usuario.
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al enviar el formulario!',
          //footer: '<a href="">Why do I have this issue?</a>'
        });
      });
  };

  return (
    <div className={style.register}>
      <div className={style.subContainer}>
        {info ? <h2>{info.tittle}</h2> : <h2>¡Agendar reunión ahora!</h2>}

        <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
          <div className={style.casillero}>
            <label>Nombre y apellido:</label>
            <input
              className={style.casilleroInput}
              type="text"
              placeholder="Ingrese su nombre y apellido aquí"
              {...register('nombre', {
                required: true,
                pattern: /^([a-zA-Z]+)(\s[a-zA-Z]+)*$/,
                maxLength: 15,
              })}
            />
            {errors.empresa?.type === 'required' && (
              <p className={style.error}>El campo nombre es requerido</p>
            )}
            {errors.empresa?.type === 'pattern' && (
              <p className={style.error}>
                El formato de nombre y apellido es incorrecto
              </p>
            )}
            {errors.empresa?.type === 'maxLength' && (
              <p className={style.error}>
                El campo nombre debe tener menos de 15 caracteres
              </p>
            )}
          </div>

          <div className={style.casillero}>
            <label>Correo electrónico:</label>
            <input
              className={style.casilleroInput}
              type="text"
              placeholder="ejemplo@email.com"
              {...register('email', {
                required: true,
                pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
              })}
            />
            {errors.email?.type === 'required' && (
              <p className={style.error}>El campo email es requerido</p>
            )}
            {errors.email?.type === 'pattern' && (
              <p className={style.error}>El formato del email es incorrecto</p>
            )}
          </div>

          <div className={style.casillero}>
            <label>Número de contacto:</label>
            <input
              className={style.casilleroInput}
              type="number"
              placeholder="Ingrese su número de contacto aquí"
              {...register('telefono', {
                required: true,
              })}
            />
            {errors.telefono?.type === 'required' && (
              <p className={style.error}>El campo contacto es requerido</p>
            )}
          </div>

          <div className={style.casillero}>
            <label>Indicar el servicio de interés:</label>
            <select
              className={style.select}
              {...register('servicio', {
                required: true,
              })}
            >
              <option
                value="Indique servicio a solicitar..."
                disabled
                selected
              >
                Indique servicio a solicitar...
              </option>
              <option
                value="Servicio de marketing digital"
                className={style.optionColor}
              >
                Servicio de marketing digital
              </option>
              <option
                value="Servicios integrales"
                className={style.optionColor}
              >
                Servicios integrales
              </option>

              <option
                value="Servicios de análisis de datos"
                className={style.optionColor}
              >
                Servicios de análisis de datos
              </option>
              <option
                value="Servicios de diseño UI/UX"
                className={style.optionColor}
              >
                Servicios de diseño UI/UX
              </option>
            </select>
            {errors.servicio?.type === 'required' && (
              <p className={style.error}>El campo servicio es requerido</p>
            )}
          </div>

          <input
            type="submit"
            value="Enviar información"
            style={boton}
            className={style[info ? 'inputEnviar2' : 'inputEnviar']}
          />
        </form>
      </div>
    </div>
  );
};

export default Register;

/* <div className="select" tabIndex="1">
                    <input type="radio" className="option-select" name="test" id="opt1" checked />
                    <label for="opt1" className="option" >option1</label> 

                    <input type="radio" className="option-select" name="test" id="opt2" checked />
                    <label for="opt2" className="option" >option2</label>

                    <input type="radio" className="option-select" name="test" id="opt3" checked />
                    <label for="opt3" className="option" >option3</label>

                    <input type="radio" className="option-select" name="test" id="opt4" checked />
                    <label for="opt4" className="option" >option4</label>

                    <input type="radio" className="option-select" name="test" id="opt5" checked />
                    <label for="opt5" className="option" >option5</label>
                    
                </div> */
