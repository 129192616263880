import { useState, useEffect } from 'react';

export default function useSuperponen(idAbsoluto) {
  const [superponen, SetSuperPonen] = useState(false);

  useEffect(() => {
    const elementoEstatico = document.getElementById('contentLandingEstatico');
    const elementoAbsoluto = document.getElementById(idAbsoluto);

    if (elementoEstatico !== null && elementoAbsoluto !== null) {
      window.addEventListener('scroll', (event) => {
        const elementoEstaticoRect = elementoEstatico.getBoundingClientRect();
        const elementoAbsolutoRect = elementoAbsoluto.getBoundingClientRect();
        // Comprueba si los elementos se superponen
        if (
          /* elementoAbsolutoRect.left < elementoEstaticoRect.right &&
          elementoAbsolutoRect.right > elementoEstaticoRect.left && 
          elementoAbsolutoRect.top < elementoEstaticoRect.bottom && */
          elementoAbsolutoRect.bottom > elementoEstaticoRect.top
        ) {
          SetSuperPonen(true);
        } else {
          SetSuperPonen(false);
        }
      });
    } else {
      SetSuperPonen(true);
    }
  }, [superponen, idAbsoluto]);
  return superponen;
}
