import img1 from './assets/16.png';
import img2 from './assets/17.png';
import img3 from './assets/18.png';

export  const data = [
    {
      id: 1,
      image: img1,
      name: 'Leandro rocha',
      titulo: 'Project Manager en SP',
      frase:
        ' Proyment fue ágil desde el primer contacto, valoro la simplicidad y rapidez para solucionar problemas específicos.',
    },
    {
      id: 2,
      image: img2,
      name: 'Jonh Montoro',
      titulo: 'Gerente de Operaciones en Tom Gutie Company ',
      frase:
        ' Nuestra experiencia con Proyment fue excelente, pudieron brindarnos un conjunto de planes orientado a nuestras necesidades. Luego de dos meses de arduo trabajo, pudimos cumplir con los objetivos empresariales.',
    },
    {
      id: 3,
      image: img3,
      name: 'Matt Cannon',
      titulo: 'Marketing director at ABN8 Trading ',
      frase:
        ' Tivemos que realizar un importante lançamento de marca no Brasil e a Proyment contribuiu em grande parte para os ótimos que tivemos ',
    },
  ];