import styles from './Section3.module.scss';
import image1 from './assets/Group 762241.png';
import icon1 from './assets/icon.png';
import image2 from './assets/Group 762242.png';
import icon2 from './assets/icon 2.png';
import image2m from './assets/Frame 763018.png';
import vector from './assets/Vector 53.png';
import vector2 from './assets/Vector 53 (1).png';

export default function Section3() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.box1}>
          <div className={styles.box1Text}>
            <img src={vector} alt="" className={styles.vector} />
            <h2>Nuestra misión</h2>
            <p>
              Solucionar problemas a través de proyectos desarrollando personas
              que logren fortalecer y potenciar a las organizaciones.
            </p>
          </div>
          <div className={styles.image1}>
            <img src={image1} alt="Persona trabajando" className={styles.img1}  loading='lazy'/>
            <img src={icon1} alt="Icono de tuerca" className={styles.icon1} loading='lazy'/>
          </div>
        </div>
        <div className={styles.box2}>
          <div className={styles.image2}>
            <img src={image2} alt="Persona trabajando" className={styles.img2} loading='lazy'/>
            <img src={image2m} alt="" className={styles.image2Mobile} loading='lazy'/>
            <img src={icon2} alt="" className={styles.icon2} loading='lazy'/>
          </div>
          <div className={styles.box2Text}>
            <img src={vector2} alt="" className={styles.vector} />
            <h2>Nuestros valores</h2>
            <p>
              Nuestros pilares corporativos nos hacen cumplir con resultados de
              nuestros clientes a través de:
            </p>
            <span>
              Trabajo en equipo
              <br />
              Orientación hacia los resultados <br />
              Aprendizaje constante
              <br />
              Capacidad para innovar
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
