import styles from './Services.module.scss';
import { data } from './Data';
import { Link } from 'react-router-dom';
import scrollToTop from '../../../hooks/scrollToTop';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/pagination';
import '../ADNProyment/Carrousel.scss';

export default function Services() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contentTitle}>
            <h1>Nuestros Servicios</h1>
            <p>
              Potenciamos y protegemos el valor de sus negocios a través de la
              experiencia e innovación tecnológica para alcanzar resultados
              sostenibles.
            </p>
          </div>

          <div className={styles.contentSwiper}>
            <div className={styles.contentSlide}>
              <Swiper
                modules={[Pagination, Autoplay]}
                pagination={{ el: '.services-pagination', clickable: true }}
                className={styles.carrousel}
                autoplay={{
                  delay: 4000,
                  pauseOnMouseEnter: false,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                  },
                  375: {
                    slidesPerView: 1.07,
                    spaceBetween: 10,
                  },
                  400: {
                    slidesPerView: 1.15,
                    spaceBetween: 12,
                  },
                  425: {
                    slidesPerView: 1.2,
                    spaceBetween: 12,
                  },
                  450: {
                    slidesPerView: 1.25,
                    spaceBetween: 12,
                  },
                  475: {
                    slidesPerView: 1.35,
                    spaceBetween: 12,
                  },
                  500: {
                    slidesPerView: 1.4,
                    spaceBetween: 12,
                  },
                  525: {
                    slidesPerView: 1.45,
                    spaceBetween: 12,
                  },
                  550: {
                    slidesPerView: 1.5,
                    spaceBetween: 12,
                  },
                  768: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  800: {
                    slidesPerView: 2.75,
                    spaceBetween: 10,
                  },
                  910: {
                    slidesPerView: 2.5,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1220: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                  1440: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                  },
                }}
              >
                {data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={styles.card}>
                      <div className={styles.imgContent}>
                        <img src={item.image} alt={`${item.title} Img`} loading='lazy'/>
                      </div>
                      <div className={styles.text}>
                        <h3>{item.title}</h3>
                        <p>{item.paragraph}</p>
                        <Link
                          to={item.href}
                          onClick={scrollToTop}
                          className={styles.btn}
                        >
                          Ver más<i className="ri-arrow-right-line"></i>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="services-pagination" />
          </div>
        </div>
      </div>
    </>
  );
}
