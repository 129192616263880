import styles from './Calendario.module.scss';
import './Calendar.scss';

import fede from './assets/Fede.png';
import mensajeFede from './assets/Frame 763061.png';
import CalendlyWidget from '../../../Components/CalendlyWidget/CalendlyWidget ';

export default function Calendario() {
  return (
    <section id="HagamosResultados" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.profile}>
            <img src={fede} alt="Profile-Avatar" loading='lazy'/>
            <h1>Federico Brunet</h1>
          </div>
          <img
            src={mensajeFede}
            className={styles.mensajeFede}
            alt="MensajeFede"
          ></img>
        </div>
        {/* <div className={styles.calendarContainer}>
          <h2>Seleccione fecha:</h2>
          <Calendar 
          calendarType='gregory'
          />
        </div> */}
          <CalendlyWidget/>
      </div>
    </section>
  );
}
