import styles from './Section2.module.scss';

export default function Section2() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.titleContent}>
          <h1>Nuestras estadísticas</h1>
          <span>Proyment</span>
        </div>
        <div className={styles.text}>
          <div className={styles.box}>
            <h2>+3</h2>
            <p>
              Años de <br /> experiencia
            </p>
          </div>
          <div className={styles.box}>
            <h2>+25</h2>
            <p>
              Empresas en más de 8 <br /> distintos sectores
            </p>
          </div>
          <div className={styles.box}>
            <h2>+50</h2>
            <p>
              Proyectos <br />
              ejecutados
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
