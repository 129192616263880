import styles from './Seccion1Logos.module.scss';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context('./logos', false, /\.(jpg|jpeg|png|gif|svg)$/),
);

export default function Seccion1Logos() {
  return (
    <section id="Seccion1-Logos" className={styles.container}>
      <h1>
        Cada vez más empresas
        <span> confían en nosotros.</span>
      </h1>
      <div className={styles.slider}>
        <div className={styles.slideTrack}>
          {images.map((image, index) => (
            <div key={index} className={styles.slide}>
              <img src={image} alt={image.default} />
            </div>
          ))}
          {images.map((image, index) => (
            <div key={index} className={styles.slide}>
              <img src={image} alt={image.default} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
