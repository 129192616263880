import Calendario from '../../features/Planes.feature/Calendario/Calendario';
import ConoceNuestrosPlanes from '../../features/Planes.feature/ConoceNuestrosPlanes/ConoceNuestrosPlanes';
import HagamosResultados from '../../features/Planes.feature/HagamosResultados/HagamosResultados';
import MasDe100Empresas from '../../features/Planes.feature/MasDe100Empresas/MasDe100Empresas';
import NosEnfocamos from '../../features/Planes.feature/NosEnfocamos/NosEnfocamos';
import NuestroProducto from '../../features/Planes.feature/NuestroProducto/NuestroProducto';
import PlanesDetalles from '../../features/Planes.feature/PlanesDetalles/PlanesDetalles';
import PlanesLanding from '../../features/Planes.feature/PlanesLanding/PlanesLanding';

export default function PlanPrime() {
  return (
    <>
      <PlanesLanding />
      <MasDe100Empresas />
      <PlanesDetalles/>
      <Calendario />
      <NuestroProducto />
      <NosEnfocamos />
      <ConoceNuestrosPlanes/>
      <HagamosResultados />
    </>
  );
}
