import React from 'react';
import style from './footer.module.scss';
import logo from '../NavBar/logoPrueba.svg';
import 'remixicon/fonts/remixicon.css';
import { Link } from 'react-router-dom';
import scrollToTop from "../../hooks/scrollToTop";
import { numberPhone, whatsAppMessage } from '../../hooks/staticData';
import { useLocation } from 'react-router-dom';


export default function Footer() {
  const location = useLocation();
  const urlRoute = location.pathname
 
  return (
    <div className={`${style.container } ${urlRoute === "/agendar-reunion" ? style.container2 : ""}`}>
      <footer className={style.footer}>
        <div className={style.contentLogo}>
          <Link to={'/'} onClick={scrollToTop}>
            <img src={logo} alt="proyMent" />
          </Link>
          <span>© Proyment. Todos los</span>
          <span> derechos reservados.</span>
        </div>
        <div className={style.contentBox}>
          <h3 className={style.boxTittle}>Empresa</h3>
          <div className={style.contentList}>
          <Link to="/sobre-nosotros" onClick={scrollToTop} >Sobre Proyment</Link>
          <Link to="/industrias" onClick={scrollToTop} >Industrias</Link>
          <Link to="/blog" onClick={scrollToTop} >Blog</Link>
          <Link to="/recursos" onClick={scrollToTop} >Recursos</Link>
          </div> 
        </div>
        <div className={style.contentBox}>
          <h3 className={style.boxTittle}>Servicios</h3>
          <div className={style.contentList}>
            <Link to="/servicios/marketing-digital" onClick={scrollToTop}>Servicio de Marketing</Link>
            <Link to="/servicios/servicios-integrales" onClick={scrollToTop}>Servicios integrales</Link>
            <Link to="/servicios/analisis-datos" onClick={scrollToTop}>
              Servicios de análisis de datos
            </Link>
            <Link to="/servicios/diseño-web" onClick={scrollToTop}>Servicios de diseño web UI/UX</Link>
          </div>
        </div>
        <div className={style.contentBox}>
          <h3 className={style.boxTittle}>Productos</h3>
          <div className={style.contentList}>
          <Link to="/productos/plan-standard" onClick={scrollToTop}>Plan Standard</Link>
          <Link to="/productos/plan-avanzado" onClick={scrollToTop}>Plan Avanzado</Link>
          <Link to="/productos/plan-prime" onClick={scrollToTop}>Plan Prime</Link>
          </div>
        </div>
        <div className={style.contentBox}>
            <h3 className={style.boxTittle}>Contacto</h3>
          <Link to={`https://api.whatsapp.com/send?phone=${numberPhone}&text=${whatsAppMessage}`}>
            <button>Contactarnos</button>
          </Link>
        </div>
        <div className={style.contentBox}>
          <h3 className={style.boxTittle}>Redes Sociales</h3>
          <div className={style.contentList}>
            <div className={style.social}>
              <span>
                <i className="ri-facebook-line"></i>
              </span>
              <a href="https://www.facebook.com/Proyment" target="blank">
                <h4>Facebook</h4>
              </a>
            </div>
            <div className={style.social}>
              <span>
                <i className="ri-instagram-line"></i>
              </span>
              <a
                href="https://instagram.com/proyment?igshid=YmMyMTA2M2Y="
                target="blank"
              >
                <h4>Instagram</h4>
              </a>
            </div>
            <div className={style.social}>
              <span>
                <i className="ri-linkedin-line"></i>
              </span>
              <a
                href="https://www.linkedin.com/company/proyment/"
                target="blank"
              >
                <h4>LinkedIn</h4>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
