import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home/Home';
import ServiciosIntegrales from './pages/ServiciosIntegrales/ServiciosIntegrales';
import Marketing from './pages/marketing/marketing';
import ServiciosAnalisisDeDatos from './pages/ServiciosAnalisisDeDatos/ServiciosAnalisisDeDatos';
import ServiciosUXUI from './pages/ServiciosUXUI/ServiciosUXUI';
import PayPalCheckout from './PayPal/PaypalCheckout';
import SobreNosotros from './pages/SobreNosotros/SobreNosotros';
import PlanStandard from './pages/Planes/PlanStandard';
import Industrias from './pages/Industrias/Industrias';
import Recursos from './pages/Recursos/Recursos';
import PlanAvanzado from './pages/Planes/PlanAvanzado';
import PlanPrime from './pages/Planes/PlanPrime';
import PlanesLanding from './features/Planes.feature/PlanesLanding/PlanesLanding';
import AgendarReunion from './Components/AgendarReunion/AgendarReunion';
import Blog from './features/Blog.feature/Page';
import BlogPostRender from './features/Blog.posts.feature/components/BlogPostRender';
import { postsList } from './features/Blog.posts.feature/posts.markdown/dataPosts';

function App() {
  return (
    <>
      <Routes>
        <Route exact path={'/'} element={<Home></Home>}></Route>
        <Route exact path={'/blog'} element={<Blog />}></Route>
        {postsList.map((post) => (
          <Route
            key={post.id}
            exact
            path={`/blog/${post.metadaBlog.tags}/${post.title.replace(/ /g, '-')}`}
            element={
              <BlogPostRender
                metadata={{
                  id: post.id,
                  title: post.title,
                  img: post.img,
                  subtitle: post.subtitle,
                  tags: post.metadaBlog.tags,
                }}
                filename={post.metadaBlog.route}
              />
            }
          ></Route>
        ))}

        <Route exact path={'/productos'} element={<PlanesLanding />}></Route>
        <Route
          exact
          path={'/productos/plan-standard'}
          element={<PlanStandard />}
        ></Route>
        <Route
          exact
          path={'/productos/plan-avanzado'}
          element={<PlanAvanzado />}
        ></Route>
        <Route
          exact
          path={'/productos/plan-prime'}
          element={<PlanPrime />}
        ></Route>

        <Route exact path={'/recursos'} element={<Recursos />}></Route>
        <Route exact path={'/industrias'} element={<Industrias />}></Route>
        <Route
          exact
          path={'/agendar-reunion'}
          element={<AgendarReunion />}
        ></Route>
        <Route
          exact
          path={'/sobre-nosotros'}
          element={<SobreNosotros />}
        ></Route>
        <Route
          exact
          path={'/servicios/servicios-integrales'}
          element={<ServiciosIntegrales></ServiciosIntegrales>}
        ></Route>
        <Route
          exact
          path={'/servicios/marketing-digital'}
          element={<Marketing></Marketing>}
        ></Route>
        <Route
          exact
          path={'/servicios/analisis-datos'}
          element={<ServiciosAnalisisDeDatos />}
        ></Route>
        <Route
          exact
          path={'/servicios/diseño-web'}
          element={<ServiciosUXUI></ServiciosUXUI>}
        ></Route>

        <Route exact path={'/Paypal200'} element={<PayPalCheckout />}></Route>
      </Routes>
    </>
  );
}

export default App;
