import styles from './MasDe100Empresas.module.scss';

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context(
    '../../Home.feature/Seccion1Logos/logos',
    false,
    /\.(jpg|jpeg|png|gif|svg)$/,
  ),
);

export default function MasDe100Empresas() {
  return (
    <section id="MasDe100Empresas" className={styles.section}>
      <div className={styles.container}>
        <h1>
          Usamos el mismo método estratégico aplicado en las siguientes empresas{' '}
        </h1>
        <h2>Más de 100 clientes</h2>
        <h3>En más de 7 países confían en Proyment para su crecimiento.</h3>

        <div className={styles.slider}>
          <div className={styles.slideTrack}>
            {images.map((image, index) => (
              <div key={index} className={styles.slide}>
                <img src={image} alt={`${index + 1} Icon`} loading='lazy'/>
              </div>
            ))}
            {images.map((image, index) => (
              <div key={index} className={styles.slide}>
                <img src={image} alt={`${index + 1} Icon`} loading='lazy' />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
