import styles from './Section4.module.scss';
import { Link } from 'react-router-dom';
import scrollToTop from '../../../hooks/scrollToTop';

export default function Section4() {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <h1>Compromiso en Proyment</h1>
        <p>
          Asumimos el compromiso con las empresas que busquen potenciar su
          negocio constantemente
        </p>
        <Link to="/agendar-reunion" onClick={scrollToTop} style={{textDecoration:"none"}}>
          <button>
            Agendar reunión <i className="ri-arrow-right-line"></i>
          </button>
        </Link>
      </div>
    </section>
  );
}
